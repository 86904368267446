import { Trans, useTranslation } from 'react-i18next';

import { Box, Text } from 'libComponents';

import styles from './styles.module.css';

import { ReactComponent as PriceTriangle } from './img/priceTriangle.svg';

export default function PriceTag() {
    const { t } = useTranslation();

    return (
        <Box backgroundColor="surface-hovered" className={styles.priceTag} paddingBottom={2} paddingTop={7}>
            <PriceTriangle className={styles.priceTagPike} />

            <Box className={styles.priceTagCost}>
                <Text
                    type="h6"
                    text={t('paymentFlow.paymentIntroductory.guidesCard.freePrice')}
                    color="text-secondary-subdued"
                />
                <Text color="text-secondary-subdued" type="caption-smallest" className={styles.priceTagPeriod}>
                    /
                    <Trans i18nKey="paymentFlow.paymentIntroductory.guidesCard.perState" />
                </Text>
            </Box>
        </Box>
    );
}
