import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { getPriceFromCents } from 'helpers/utils';

import { Box, Text } from 'libComponents';
import { PriceTagPropsType } from 'components/ConfigurablePriceCard/types';

import styles from './PriceTag.module.css';

import { ReactComponent as PriceTriangle } from './img/priceTriangle.svg';

const cx = classNames.bind(styles);

export default function PriceTag({ currencySign, price, isActive, period }: PriceTagPropsType) {
    const { t } = useTranslation();

    const [firstCharAtPrice, lastCharAtPrice = '0'] = getPriceFromCents(price).toString().split('.');
    const isZeroCents = lastCharAtPrice === '0';
    const lastCharAtPriceResult = lastCharAtPrice?.length === 1 ? `${lastCharAtPrice}0` : lastCharAtPrice;

    const tagPriceColor = isActive ? 'text-secondary' : 'text-secondary-subdued';

    return (
        <Box
            backgroundColor="surface-hovered"
            className={cx('priceTag', { priceTagActive: isActive })}
            paddingBottom={2}
            paddingTop={2}
        >
            <PriceTriangle className={styles.priceTagPike} />

            <Text type="caption" bold text={currencySign} color={tagPriceColor} />

            <Box className={styles.priceTagCost}>
                <Text type="h2" text={firstCharAtPrice} color={tagPriceColor} />
                <Box className={styles.priceTagCostRightCol}>
                    <div className={styles.priceTagCents}>
                        {!isZeroCents && (
                            <Text text={lastCharAtPriceResult} color={tagPriceColor} type="small-text" bold />
                        )}
                    </div>
                    <Text
                        color={tagPriceColor}
                        type="caption-smallest"
                        className={styles.priceTagPeriod}
                        text={t(period)}
                    />
                </Box>
            </Box>
        </Box>
    );
}
