import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectRedesignedFlow } from 'redux/redesignedFlow/selectors';
import { selectOnboarding } from 'redux/onboadring/selectors';

import { DEFAULT_WEIGHT } from 'constants/values';
import { DATE_FORMATS } from 'constants/measureUnits';

import Convert from 'helpers/Convert';

import { getGoalsTitles } from '../config';

import dayjs from 'dayjs';

export const useGetParameters = () => {
    const { target_weight, measureSystem, occasionResultConfig } = useSelector(selectOnboarding);
    const { configPaymentParams } = useSelector(selectRedesignedFlow);

    const { t } = useTranslation();

    const convert = new Convert(measureSystem);
    const targetWeight = convert.getWeight(target_weight || DEFAULT_WEIGHT);
    const unit = convert.getUnit();

    const goalsTitles = getGoalsTitles(configPaymentParams?.goalsTitles, t);
    const goalsTitlesJoined = goalsTitles.map((item) => t(item)).join(', ');

    const targetDate = occasionResultConfig.targetDate || dayjs().add(3, 'months');

    return [
        {
            name: 'type',
            title: 'configurablePayment.goalsBlocks.image.targetBody',
            value: t(configPaymentParams.targetBodyTitle || 'onboardingFlow.obBodyTypeTargetA.female.option1'),
        },
        {
            name: 'goal',
            title: 'paymentFlow.paymentDa.beforeAfter.goal',
            value: goalsTitlesJoined,
        },
        {
            name: 'weight',
            title: 'paymentFlow.paymentDa.userInfo.titleWeight',
            value: t('configurablePayment.goalsBlocks.parameters.weight.value', { unit, targetWeight }),
        },
        {
            name: 'date',
            title: 'configurablePayment.goalsBlocks.image.date',
            value: t('configurablePayment.goalsBlocks.image.date.value', {
                targetMonth: dayjs(targetDate).format(DATE_FORMATS.MMM),
                targetDay: dayjs(targetDate).format(DATE_FORMATS.DD),
            }),
        },
    ];
};
