import { Rating } from 'wikr-core-components';
import { Trans } from 'react-i18next';

import { Text } from 'libComponents';

import styles from './ReviewDes.module.css';

import { IReviewDes } from 'types/pages/onboardingPages/commonTypes';

const ReviewDes = ({ author, text }: IReviewDes) => (
    <div className={styles.wrapper}>
        <div>
            <Rating backgroundColor="primary-default" rateValue={5} emptyStarColor="primary-default" />
            <Text color="text-secondary" className={styles.commentText}>
                <Trans i18nKey={text} />
            </Text>
        </div>

        <Text type="small-text" color="text-secondary-subdued">
            <Trans i18nKey={author} />
        </Text>
    </div>
);

export default ReviewDes;
