import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/user/selectors';
import { selectIsLatamOn } from 'redux/payment/selectors';

import { isCountryWithEnormousPrice } from 'helpers/payment/latam';

import EnormousPriceCard from './components/EnormousPriceCard';
import DefaultCard from './components/DefaultCard';

import { ConfigurablePriceCardPropsType } from './types';

export default function ConfigurablePriceCard(props: ConfigurablePriceCardPropsType) {
    const country = useSelector(selectCountry);
    const isLatamOn = useSelector(selectIsLatamOn);

    if (isLatamOn && isCountryWithEnormousPrice(country)) return <EnormousPriceCard {...props} />;

    return <DefaultCard {...props} />;
}
