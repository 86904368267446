import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectRedesignedFlow = (state: RootState) => state.redesignedFlow;

export const selectIsRedesignedFlow = createSelector(selectRedesignedFlow, (storeSlice) => storeSlice.isRedesignedFlow);
export const selectHeaderSettings = createSelector(selectRedesignedFlow, (storeSlice) => storeSlice.headerSettings);

export { selectRedesignedFlow };
