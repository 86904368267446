import { Trans } from 'react-i18next';

import { Image, Text } from 'libComponents';
import { TextDes } from 'components/redesigned/common';

import styles from './styles.module.css';

import { getWhatYouGetList } from './config';

const WhatIncludedIconsDes = () => {
    const whatYouGetList = getWhatYouGetList();

    return (
        <div className={styles.container}>
            <TextDes type="h4" center mb={24}>
                <Trans i18nKey="onboardingFlow.paymentI1Golf17.whatYouGet.title" />
            </TextDes>

            <ul className={styles.list}>
                {whatYouGetList.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                        <div className={styles.listItem__icon}>
                            <Image src={item.icon} alt="Icon" maxWidth={32} />
                        </div>
                        <Text color="text-secondary">
                            <Trans
                                {...item.translationOptions}
                                components={{ 1: <span className={styles.listItemNumber} /> }}
                            />
                        </Text>
                    </li>
                ))}
            </ul>

            <Text color="text-secondary-subdued" center>
                <Trans i18nKey="paymentFlow.paymentDa.whatYouGet.item2" />
            </Text>
        </div>
    );
};

export default WhatIncludedIconsDes;
