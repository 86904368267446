import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/user/selectors';

const useGetAllFreeGuidesPrice = () => {
    const country = useSelector(selectCountry);

    switch (country) {
        case 'MX':
            return '515';

        case 'BR':
            return '145';

        case 'CO':
            return '119';

        case 'PE':
            return '115';

        case 'CL':
            return '26100';

        default:
            return '30.00';
    }
};

export default useGetAllFreeGuidesPrice;
