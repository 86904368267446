import {
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_YEAR,
} from 'constants/payments';

import { getSubscriptionTitle } from 'helpers/subscriptions';
import { GetWeekwordingTranslationOptionsType } from 'helpers/configurablePaymentScreen/types';

import type { ConfigurablePaymentBlockType } from 'types/payments/configurablePaymentScreen';

export const getBlocksToRender = (blocks: ConfigurablePaymentBlockType[]) => blocks?.filter((item) => item.block_type);

const CUSTOM_DAYS_PER_MONTH = 28;
const CUSTOM_DAYS_PER_SIX_MONTH = 180;

const WEEK_PERIODS = [
    { period: 7, count: 1, context: 'week' },
    { period: 14, count: 2, context: 'week' },
    { period: 21, count: 3, context: 'week' },
    { period: DAYS_PER_MONTH, count: 4, context: 'week' },
    { period: DAYS_PER_TWO_MONTH, count: 8, context: 'week' },
    { period: DAYS_PER_THREE_MONTH, count: 12, context: 'week' },
    { period: DAYS_PER_FOUR_MONTH, count: 16, context: 'week' },
    { period: DAYS_PER_FIVE_MONTH, count: 20, context: 'week' },
    { period: DAYS_PER_SIX_MONTH, count: 24, context: 'week' },
    { period: DAYS_PER_YEAR, count: 52, context: 'week' },
];

const getWeekwordingWeekCount = (daysAmount: number) => {
    const currentPeriod = WEEK_PERIODS.find((item) => item.period === daysAmount);
    // If we ended up with non-standard period, we calculate it manually

    return currentPeriod ? currentPeriod.count : Math.round(daysAmount / DAYS_PER_WEEK);
};

// This method handles products with subscription periods (1month, 3month, 6month 1year) that we have currently
// New products require changes in this method
export const getWeekwordingTranslationOptions: GetWeekwordingTranslationOptionsType = ({
    trial,
    period,
    weekwording,
}) => {
    let trialOptions, periodOptions;

    switch (weekwording) {
        case 'weeks':
            trialOptions = { count: getWeekwordingWeekCount(trial), context: 'week' };
            periodOptions = { count: getWeekwordingWeekCount(period), context: 'week' };
            break;

        case 'days2':
            const days2RegularCount = period === DAYS_PER_SIX_MONTH ? CUSTOM_DAYS_PER_SIX_MONTH : period;

            trialOptions = { count: trial, context: 'day' };
            periodOptions = { count: days2RegularCount, context: 'day' };
            break;

        case 'days':
            let daysRegularCount = period;

            if (period === DAYS_PER_MONTH) daysRegularCount = CUSTOM_DAYS_PER_MONTH;
            if (period === DAYS_PER_SIX_MONTH) daysRegularCount = CUSTOM_DAYS_PER_SIX_MONTH;

            trialOptions = { count: trial, context: 'day' };
            periodOptions = { count: daysRegularCount, context: 'day' };
            break;

        default:
            const { count, context } = getSubscriptionTitle(period, false);

            trialOptions = { count: trial, context: 'day' };
            periodOptions = { count: Number(count), context };
    }

    return { trialOptions, periodOptions };
};
