import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCurrency } from 'redux/payment/selectors';

import { Box, Image, Text } from 'libComponents';

import styles from './styles.module.css';

import guides_sm from '../img/guides_sm.webp';

import PriceTag from '../PriceTag';

const DefaultCard = ({ price }: { price: string }) => {
    const { t } = useTranslation();
    const { sign } = useSelector(selectCurrency);

    return (
        <Box className={styles.guideCard} paddingX={12} paddingTop={10} paddingBottom={10}>
            <div className={styles.guideCardLeft}>
                <div className={styles.guideCardImg}>
                    <Image
                        maxWidth={60}
                        src={guides_sm}
                        alt={t('paymentFlow.paymentIntroductory.guidesCard.description')}
                    />
                </div>
                <div className={styles.guideCardInfo}>
                    <Text
                        text={t('paymentFlow.paymentIntroductory.guidesCard.title')}
                        className={styles.guideCardTitle}
                        type="caption"
                        bold
                    />
                    <Text bold text={t('paymentFlow.paymentIntroductory.guidesCard.description')} type="caption" />
                </div>
            </div>
            <div className={styles.guideCardRight}>
                <Text color="text-secondary-subdued" text={`${sign}${price}`} className={styles.lineThrough} />
                <PriceTag />
            </div>
        </Box>
    );
};

export default DefaultCard;
