export enum ConfigurablePaymentBlocks {
    DYNAMIC_STRING_BLOCK = 'dynamic_string_block',
    BEFORE_AFTER_BLOCK = 'before_after_block',
    TITLE_BLOCK = 'title_block',
    BENEFITS_BLOCK = 'benefits_block',
    SUMMARY_PLAN_BLOCK = 'summary_plan_block',
    CURRENT_STATE_BLOCK = 'current_state_block',
    SUMMARY_BLOCK = 'summary_block',
    TIMER_BADGE_BLOCK = 'timer_badge_block',
    PRODUCTS_BLOCK = 'products_block',
    CTA_AND_TITLE_BLOCK = 'cta_title_block',
    DISCLAIMER_BLOCK = 'disclaimer_block',
    APP_SCREENS_BLOCK = 'app_screens_block',
    SOCIAL_PROOF_NUMBER_ONE_BLOCK = 'social_proof_number_one_block',
    WHAT_YOU_GET_BLOCK = 'what_you_get_block',
    CALENDAR_IMAGE_BLOCK = 'calendar_image_block',
    REVIEWS_BLOCK = 'reviews_block',
    REAL_USER_BEFORE_AND_AFTER_BLOCK = 'real_user_before_and_after_block',
}

export const PRODUCTS_BLOCK_IDENTIFIER = 'products-block';
