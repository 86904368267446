/* eslint-disable max-lines */
export const paymentRoutes = [
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot3',
        route: 'payment-g1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot3',
        route: 'offer-a1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Golf/PaymentA1Golf3',
        route: 'offer-a1-golf3',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot3Full',
        route: 'payment-g1-foxtrot3-full',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot3V2',
        route: 'offer-a1-foxtrot3-v2',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf5',
        route: 'payment-g6-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf5',
        route: 'offer-a6-golf5',
    },
    {
        path: 'PaymentLandingPages/SkipTrial',
        route: 'skip-trial',
    },
    {
        path: 'PaymentLandingPages/OfferM1Golf5',
        route: 'offer-m1-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentM1Golf5',
        route: 'payment-m1-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf14',
        route: 'payment-g6-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf14',
        route: 'offer-a6-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG12Golf14',
        route: 'payment-g12-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferG12Golf14',
        route: 'offer-g12-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG7Golf14',
        route: 'payment-g7-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferA7Golf14',
        route: 'offer-a7-golf14',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG11Golf14',
        route: 'payment-g11-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf16',
        route: 'payment-g6-golf16',
    },
    {
        path: 'PaymentLandingPages/OfferA6Golf16',
        route: 'offer-a6-golf16',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG11Golf14',
        route: 'payment-g11-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG14Golf14',
        route: 'payment-g14-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferA14Golf14',
        route: 'offer-a14-golf14',
    },

    {
        path: 'PaymentLandingPages/PaymentG13Golf13',
        route: 'payment-g13-golf13',
    },
    {
        path: 'PaymentLandingPages/OfferG13Golf13',
        route: 'offer-g13-golf13',
    },
    {
        path: 'PaymentLandingPages/PaymentI1Golf17',
        route: 'payment-i1-golf17',
    },
    {
        path: 'PaymentLandingPages/OfferI1Golf17',
        route: 'offer-i1-golf17',
    },
    {
        path: 'PaymentLandingPages/PaymentG12Golf15',
        route: 'payment-g12-golf15',
    },
    {
        path: 'PaymentLandingPages/OfferA12Golf15',
        route: 'offer-a12-golf15',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG15Golf14',
        route: 'payment-g15-golf14',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG15Golf19',
        route: 'payment-g15-golf19',
    },
    {
        path: 'PaymentLandingPages/OfferG12Golf19',
        route: 'offer-g12-golf19',
    },
    {
        path: 'PaymentLandingPages/PaymentI2Golf14',
        route: 'payment-i2-golf14',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG15Golf20',
        route: 'payment-g15-golf20',
    },
    {
        path: 'PaymentLandingPages/OfferG12Golf20',
        route: 'offer-g12-golf20',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG16Golf20',
        route: 'payment-g16-golf20',
    },
    {
        path: 'PaymentLandingPages/OfferG14Golf20',
        route: 'offer-g14-golf20',
    },
    {
        path: 'PaymentLandingPages/PaymentI3Golf18',
        route: 'payment-i3-golf18',
    },
    {
        path: 'PaymentLandingPages/PaymentI2Golf20',
        route: 'payment-i2-golf20',
    },
    {
        path: 'PaymentLandingPages/OfferI1Golf20',
        route: 'offer-i1-golf20',
    },
];
