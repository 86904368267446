import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectRedesignedFlow } from 'redux/redesignedFlow/selectors';
import { selectOnboarding } from 'redux/onboadring/selectors';

import { DEFAULT_WEIGHT } from 'constants/values';

import Convert from 'helpers/Convert';

import portrait from './img/portrait.svg';
import person from './img/person.svg';
import goalImage from './img/goal.svg';

import { DEFAULT_AGE, getGoalsTitles, getLevel } from '../config';

export const useGetParameters = () => {
    const { measureSystem, target_weight } = useSelector(selectOnboarding);
    const { configPaymentParams } = useSelector(selectRedesignedFlow);

    const { t } = useTranslation();

    const convert = new Convert(measureSystem);
    const targetWeight = convert.getWeight(target_weight || DEFAULT_WEIGHT);
    const unit = convert.getUnit();

    const goalsTitles = getGoalsTitles(configPaymentParams?.goalsTitles, t);
    const goalsTitlesJoined = goalsTitles.join(', ');

    return [
        {
            name: 'level',
            title: 'configurablePayment.goalsBlocks.imageParameters.level',
            icon: getLevel(configPaymentParams?.realFitnessLevel).icon,
            value: t(getLevel(configPaymentParams?.realFitnessLevel).title),
        },
        {
            name: 'age',
            title: 'configurablePayment.goalsBlocks.parameters.age',
            icon: portrait,
            value: String(configPaymentParams?.userAge || DEFAULT_AGE),
        },
        {
            name: 'weight',
            title: 'paymentFlow.paymentDa.userInfo.titleWeight',
            icon: person,
            value: t('configurablePayment.goalsBlocks.parameters.weight.value', { unit, targetWeight }),
        },
        {
            name: 'goal',
            title: 'paymentFlow.paymentDa.beforeAfter.goal',
            icon: goalImage,
            value: goalsTitlesJoined,
        },
    ];
};
