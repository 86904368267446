import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { InitConfig } from '@solidgate/client-sdk-loader';

import {
    selectIsApplePayAvailable,
    selectIsDisplayCustomPaymentButton,
    selectIsGooglePayAvailable,
} from 'redux/payment/selectors';
import { setIsApplePayAvailable, setIsGooglePayAvailable } from 'redux/payment/actions';

import {
    APPLE_PAY_LITERAL,
    CREDIT_CARD_LITERAL,
    PAYPAL_LITERAL,
    ONE_CLICK_LITERALS,
    GOOGLE_PAY_LITERAL,
} from 'constants/payments';
import { FORM_STYLES } from 'constants/formStyles';

import { useSolidHandlers } from 'components/PaymentMethods/hooks/useSolidHandlers';
import { usePaymentData } from 'components/PaymentMethods/hooks/usePaymentData';

import { getApplePayButtonParams, getFormParams, getGooglePayButtonParams } from 'helpers/payment';
import { isAndroid, isIOS } from 'helpers/devices';

import { Button, Image, Text } from 'libComponents';
import PayPalOption from 'components/PayPalOption/PayPalOption';
import SolidPaymentForm from 'components/PaymentMethods/components/SolidPaymentForm';

import styles from './DefaultVerticalPaymentMethods.module.css';

import payPalLogo from '../img/payPalLogo.webp';
import googlePayLogo from '../img/googlePayLogo.webp';
import bankCardImg from '../img/BankCard.webp';
import applePayLogo from '../img/applePayLogo.webp';

import { DefaultVerticalPaymentMethodsPropsType } from './types';

const cx = classNames.bind(styles);

const DefaultVerticalPaymentMethods = ({
    withApplePay = false,
    withGooglePay = false,
    activeOption,
    handleChange,
    payment,
    isPreSelect,
    isHidePayPal,
    onOverlayClick,
    isLegalCheckboxChecked,
}: DefaultVerticalPaymentMethodsPropsType) => {
    const isApplePayAvailable = useSelector(selectIsApplePayAvailable);
    const isGooglePayAvailable = useSelector(selectIsGooglePayAvailable);

    const isCardFirstOnIOS = isIOS && !isGooglePayAvailable;
    const isCardFirstOnAndroid = isAndroid && isGooglePayAvailable;
    const isAnyOtherDevice = !isAndroid && !isIOS;

    const wrapperClasses = cx('methodsButtonWrapper', {
        firstMethod: isPreSelect || (isHidePayPal && (isCardFirstOnIOS || isCardFirstOnAndroid || isAnyOtherDevice)),
    });

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const appleContainerRef = useRef(null);
    const googleContainerRef = useRef(null);

    const [hasPaymentError, setHasPaymentError] = useState<boolean>(false);
    const [hasPaymentSuccess, setHasPaymentSuccess] = useState<boolean>(false);

    const isDisplayPaymentButton = useSelector(selectIsDisplayCustomPaymentButton);

    const { paymentData, currentProduct, merchantData } = usePaymentData();

    const {
        handleOnError,
        handleOnFail,
        handleOnSuccess,
        handleOnSubmit,
        handleCard,
        handleOnReadyPaymentInstance,
        handleOnMounted,
    } = useSolidHandlers({
        currentProduct,
        paymentData,
        payment,
        activePayment: activeOption,
        setHasPaymentError,
        setHasPaymentSuccess,
    });

    useEffect(() => {
        return () => {
            dispatch(setIsApplePayAvailable(false));
            dispatch(setIsGooglePayAvailable(false));
        };
    }, []);

    const formParams = getFormParams(isDisplayPaymentButton, false);
    const applePayButtonParams = getApplePayButtonParams(withApplePay);
    const googlePayButtonParams = getGooglePayButtonParams(withGooglePay);

    const inactiveApplePayWrapper = cx('applePayBtn', {
        paymentInactive: hasPaymentError || hasPaymentSuccess || !isApplePayAvailable,
    });

    const inactiveGooglePayWrapper = cx('googlePayBtn', {
        paymentInactive: hasPaymentError || hasPaymentSuccess || !isGooglePayAvailable,
    });

    const payPalOneClickWrapper = cx('payPalWrapper', {
        payPalOrder: isApplePayAvailable && isPreSelect,
    });

    const isHideOneClick = !isApplePayAvailable && !isGooglePayAvailable && isHidePayPal;

    const labelWrapper = cx('labelWrapper', {
        paymentInactive: isHideOneClick,
    });

    const oneClickButtonsWrapper = cx('oneClickButtonsWrapper', {
        legalCheckboxOverlay: onOverlayClick && !isLegalCheckboxChecked,
    });

    const isOneClickOptionChecked = ONE_CLICK_LITERALS.includes(activeOption);

    return (
        <div className={wrapperClasses}>
            <label className={labelWrapper}>
                <input type="radio" name="option" checked={isOneClickOptionChecked} className={styles.input} />
                <div className={styles.wrapper} data-locator="oneClickOption">
                    <div className={styles.paymentOptionButton}>
                        <div className={styles.titleWrapper} onClick={() => handleChange(PAYPAL_LITERAL)}>
                            <Text
                                className={styles.paymentName}
                                text={t('paymentFlow.verticalCheckout.paymentName.OneClickPayment')}
                            />
                            <div className={styles.imgWrapper}>
                                {!isHidePayPal && (
                                    <Image src={payPalLogo} alt="imgPayment" maxWidth={38} isForceVisible />
                                )}
                                {isApplePayAvailable && (
                                    <Image src={applePayLogo} alt="applePay" maxWidth={38} isForceVisible />
                                )}
                                {isGooglePayAvailable && (
                                    <Image src={googlePayLogo} alt="googlePay" maxWidth={38} isForceVisible />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.payments}>
                        <div className={styles.wrapperOneClickPayment} onClick={onOverlayClick}>
                            <div className={oneClickButtonsWrapper}>
                                <div className={payPalOneClickWrapper}>
                                    {!isHidePayPal && (
                                        <PayPalOption
                                            isPayPal
                                            titleTop={(isApplePayAvailable || isGooglePayAvailable) && !isPreSelect}
                                        />
                                    )}
                                </div>
                                <div
                                    onClick={() => handleChange(APPLE_PAY_LITERAL)}
                                    ref={appleContainerRef}
                                    className={inactiveApplePayWrapper}
                                />
                                <div
                                    ref={googleContainerRef}
                                    className={inactiveGooglePayWrapper}
                                    onClick={() => handleChange(GOOGLE_PAY_LITERAL)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </label>
            <label className={styles.labelWrapper} onClick={() => handleChange(CREDIT_CARD_LITERAL)}>
                <input
                    type="radio"
                    name="option"
                    className={styles.input}
                    checked={activeOption === CREDIT_CARD_LITERAL}
                />
                <div className={styles.wrapper} data-locator="creditCardOption">
                    <div className={styles.paymentOptionButton}>
                        <div className={styles.titleWrapper}>
                            <Text
                                className={styles.paymentName}
                                text={t('paymentFlow.verticalCheckout.paymentName.CreditCard')}
                            />
                            <div className={styles.imgWrapper}>
                                <Image src={bankCardImg} alt="imgPayment" maxWidth={100} isForceVisible />
                            </div>
                        </div>
                    </div>
                    <div className={styles.payments}>
                        <div className={styles.bankCard} onClick={onOverlayClick}>
                            <div className={oneClickButtonsWrapper}>
                                <SolidPaymentForm
                                    applePayButtonParams={applePayButtonParams}
                                    applePayContainerRef={appleContainerRef}
                                    googlePayButtonParams={googlePayButtonParams}
                                    googlePayContainerRef={googleContainerRef}
                                    merchantData={merchantData as InitConfig['merchantData']}
                                    setHasPaymentError={setHasPaymentError}
                                    styles={FORM_STYLES}
                                    formParams={formParams}
                                    onError={handleOnError}
                                    onFail={handleOnFail}
                                    onCard={handleCard}
                                    onSuccess={handleOnSuccess}
                                    onSubmit={handleOnSubmit}
                                    onReadyPaymentInstance={handleOnReadyPaymentInstance}
                                    onMounted={handleOnMounted}
                                    key={merchantData?.signature}
                                    isOpenModal
                                />
                                {isDisplayPaymentButton && !hasPaymentError && !hasPaymentSuccess && (
                                    <div id="customSubmitButton" className={styles.bankCardBtn}>
                                        <Button text="basics.continue" dataLocator="submitButton" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default DefaultVerticalPaymentMethods;
