import { getTranslationSlide } from 'helpers/utils';

import item3PT from './img/item3PT.webp';
import item3IT from './img/item3IT.webp';
import item3FR from './img/item3FR.webp';
import item3ES from './img/item3ES.webp';
import item3EN from './img/item3EN.webp';
import item3DE from './img/item3DE.webp';
import item2PT from './img/item2PT.webp';
import item2IT from './img/item2IT.webp';
import item2FR from './img/item2FR.webp';
import item2ES from './img/item2ES.webp';
import item2EN from './img/item2EN.webp';
import item2DE from './img/item2DE.webp';
import item1PT from './img/item1PT.webp';
import item1PT_wallPilates from './img/item1PT_wallPilates.webp';
import item1IT from './img/item1IT.webp';
import item1IT_wallPilates from './img/item1IT_wallPilates.webp';
import item1FR from './img/item1FR.webp';
import item1FR_wallPilates from './img/item1FR_wallPilates.webp';
import item1ES from './img/item1ES.webp';
import item1ES_wallPilates from './img/item1ES_wallPilates.webp';
import item1EN from './img/item1EN.webp';
import item1EN_wallPilates from './img/item1EN_wallPilates.webp';
import item1DE from './img/item1DE.webp';
import item1DE_wallPilates from './img/item1DE_wallPilates.webp';

export const useGetAppScreens = (isWallPilates: boolean) => {
    const slides = {
        en: [isWallPilates ? item1EN_wallPilates : item1EN, item2EN, item3EN],
        es: [isWallPilates ? item1ES_wallPilates : item1ES, item2ES, item3ES],
        pt: [isWallPilates ? item1PT_wallPilates : item1PT, item2PT, item3PT],
        fr: [isWallPilates ? item1FR_wallPilates : item1FR, item2FR, item3FR],
        de: [isWallPilates ? item1DE_wallPilates : item1DE, item2DE, item3DE],
        it: [isWallPilates ? item1IT_wallPilates : item1IT, item2IT, item3IT],
    };

    const [slideImg1, slideImg2, slideImg3] = getTranslationSlide(slides);

    return [
        {
            title: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide1.title',
            description: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide1.text',
            src: slideImg1,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide2.title',
            description: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide2.text',
            src: slideImg2,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide3.title',
            description: 'onboardingFlow.paymentI1Golf17.appScreenBlock.slide3.text',
            src: slideImg3,
        },
    ];
};
