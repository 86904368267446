/* eslint-disable max-lines */
export const onboardingRoutes = [
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObSocialProofFull',
        route: 'ob-social-proof-full',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-body-type',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/TargetZone',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/ObTargetZonesFull',
        route: 'ob-target-zones-full',
    },
    {
        path: 'OnboardingPages/BodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'OnboardingPages/ActivityLevel',
        route: 'ob-activity-level',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/PushupsSquats',
        route: 'ob-pushups-squats',
    },
    {
        path: 'OnboardingPages/YogaLevel',
        route: 'ob-yoga-level',
    },
    {
        path: 'OnboardingPages/BendingKnees',
        route: 'ob-bending-knees',
    },
    {
        path: 'OnboardingPages/MealsFeel',
        route: 'ob-meals-feel',
    },
    {
        path: 'OnboardingPages/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/ObWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight',
    },
    {
        path: 'OnboardingPages/ObTargetWeight',
        route: 'ob-weight-target',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary-fitlevel',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/EmailConsentFull',
        route: 'email-consent-full',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/PilatesLevel',
        route: 'ob-pilates-level',
    },
    {
        path: 'OnboardingPages/EmailAccount',
        route: 'email-account',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailAFull',
        route: 'email-a-full',
    },
    {
        path: 'OnboardingPages/EmailAccountA',
        route: 'email-account-a',
    },
    {
        path: 'OnboardingPages/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObGoalsOverMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/CurrentBodyMix',
        route: 'ob-body-type-mix',
    },
    {
        path: 'OnboardingPages/TargetBodyMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFull',
        route: 'ob-familiarity-full',
    },
    {
        path: 'OnboardingPages/ObTargetZonesB',
        route: 'ob-target-zones-b',
    },
    {
        path: 'OnboardingPages/ObTargetZonesC',
        route: 'ob-target-zones-c',
    },
    {
        path: 'OnboardingPages/ObGoalsB',
        route: 'ob-goals-b',
    },
    {
        path: 'OnboardingPages/CurrentBodyA',
        route: 'ob-current-body-type-a',
    },
    {
        path: 'OnboardingPages/TargetBodyA',
        route: 'ob-target-body-type-a',
    },
    {
        path: 'OnboardingPages/ObSex',
        route: 'ob-sex',
    },
    {
        path: 'OnboardingPages/ObCurrentEmotions',
        route: 'ob-current-emotions',
    },
    {
        path: 'OnboardingPages/ObPositive',
        route: 'ob-positive',
    },
    {
        path: 'OnboardingPages/ObUnfortunatelyStress',
        route: 'ob-unfortunately-stress',
    },
    {
        path: 'OnboardingPages/ObStressLook',
        route: 'ob-stress-look',
    },
    {
        path: 'OnboardingPages/ObStressTime',
        route: 'ob-stress-time',
    },
    {
        path: 'OnboardingPages/ObSleepProblems',
        route: 'ob-sleep-problems',
    },
    {
        path: 'OnboardingPages/ObFocus',
        route: 'ob-focus',
    },
    {
        path: 'OnboardingPages/ObSelftime',
        route: 'ob-selftime',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general1',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general2',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general3',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general4',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general5',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general6',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general7',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general8',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general9',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general10',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryMeditation',
        route: 'feedback-summary-meditation',
    },
    {
        path: 'OnboardingPages/EmailAFull2',
        route: 'email-a-full2',
    },
    {
        path: 'OnboardingPages/EmailConsentFull2',
        route: 'email-consent-full2',
    },
    {
        path: 'OnboardingPages/ObSleepFull',
        route: 'ob-sleep-full',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryFitLevelFull',
        route: 'feedback-summary-fitlevel-full',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-a',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-b',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-c',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-d',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-e',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-f',
    },
    {
        path: 'OnboardingPages/ObUserStory',
        route: 'ob-user-story',
    },
    {
        path: 'OnboardingPages/ObUserStoryFm',
        route: 'ob-user-story-fm',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingAConfig',
        route: 'creating-a',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingBConfig',
        route: 'creating-b',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingCConfig',
        route: 'creating-c',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingDConfig',
        route: 'creating-d',
    },
    {
        path: 'OnboardingPages/EmailD',
        route: 'email-d',
    },
    {
        path: 'OnboardingPages/ObSeeIntro',
        route: 'ob-see-intro',
    },
    {
        path: 'OnboardingPages/ObSee',
        route: 'ob-see-1',
    },
    {
        path: 'OnboardingPages/ObSee',
        route: 'ob-see-2',
    },
    {
        path: 'OnboardingPages/ObSee',
        route: 'ob-see-3',
    },
    {
        path: 'OnboardingPages/ObWalkingFeedback',
        route: 'ob-walking-feedback',
    },
    {
        path: 'OnboardingPages/ObPushupsSquatsFeedback',
        route: 'ob-pushups-squats-feedback',
    },
    {
        path: 'OnboardingPages/ObBendingKneesFeedback',
        route: 'ob-bending-knees-feedback',
    },
    {
        path: 'OnboardingPages/ObSleepFullFeedback',
        route: 'ob-sleep-full-feedback',
    },
    {
        path: 'OnboardingPages/ObWaterFeedback',
        route: 'ob-water-feedback',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFullFeedback',
        route: 'ob-familiarity-full-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-a-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-b-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-c-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-d-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-e-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-f-des',
    },
    {
        path: 'OnboardingPages/ObHeightDes',
        route: 'ob-height-des',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightDes',
        route: 'ob-weight-des',
    },
    {
        path: 'OnboardingPages/ObTargetWeightDes',
        route: 'ob-weight-target-des',
    },
    {
        path: 'OnboardingPages/ObAgeDes',
        route: 'ob-age-des',
    },
    {
        path: 'OnboardingPages/ObSocialProofDes',
        route: 'ob-social-proof-des',
    },
    {
        path: 'OnboardingPages/ObFeedbackDes',
        route: 'ob-feedback-des',
    },
    {
        path: 'OnboardingPages/ObGoalsDes',
        route: 'ob-goals-des',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFullDes',
        route: 'ob-familiarity-full-des',
    },
    {
        path: 'OnboardingPages/CurrentBodyADes',
        route: 'ob-current-body-type-a-des',
    },
    {
        path: 'OnboardingPages/TargetBodyADes',
        route: 'ob-target-body-type-a-des',
    },
    {
        path: 'OnboardingPages/ObTargetZonesCDes',
        route: 'ob-target-zones-c-des',
    },
    {
        path: 'OnboardingPages/BodyImageDes',
        route: 'ob-body-image-des',
    },
    {
        path: 'OnboardingPages/ActivityLevelDes',
        route: 'ob-activity-level-des',
    },
    {
        path: 'OnboardingPages/ObWalkingDes',
        route: 'ob-walking-des',
    },
    {
        path: 'OnboardingPages/PushupsSquatsDes',
        route: 'ob-pushups-squats-des',
    },
    {
        path: 'OnboardingPages/PilatesLevelDes',
        route: 'ob-pilates-level-des',
    },
    {
        path: 'OnboardingPages/BendingKneesDes',
        route: 'ob-bending-knees-des',
    },
    {
        path: 'OnboardingPages/ObTagsADes',
        route: 'ob-tags-a-des',
    },
    {
        path: 'OnboardingPages/MealsFeelDes',
        route: 'ob-meals-feel-des',
    },
    {
        path: 'OnboardingPages/ObSleepDes',
        route: 'ob-sleep-des',
    },
    {
        path: 'OnboardingPages/ObWaterDes',
        route: 'ob-water-des',
    },
    {
        path: 'OnboardingPages/ObDietsDes',
        route: 'ob-diets-des',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryDes',
        route: 'feedback-summary-fitlevel-des',
    },
    {
        path: 'OnboardingPages/ObOccasionDes',
        route: 'ob-occasion-des',
    },
    {
        path: 'OnboardingPages/ObOccasionResultDes',
        route: 'ob-occasion-result-des',
    },
    {
        path: 'OnboardingPages/CreatingADes',
        route: 'creating-des',
    },
    {
        path: 'OnboardingPages/EmailAccountADes',
        route: 'email-account-a-des',
    },
    {
        path: 'OnboardingPages/EmailAFullDes',
        route: 'email-a-full-des',
    },
    {
        path: 'OnboardingPages/EmailConsentFullDes',
        route: 'email-consent-full-des',
    },
    {
        path: 'OnboardingPages/YogaLevelDes',
        route: 'ob-yoga-level-des',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des1',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des2',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des3',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des4',
    },
    {
        path: 'OnboardingPages/ObGeneralDes',
        route: 'ob-general-des5',
    },
    {
        path: 'OnboardingPages/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'OnboardingPages/ObBackIssueConfig',
        route: 'ob-back-issue-config',
    },
    {
        path: 'OnboardingPages/ObCalculatingGraph',
        route: 'ob-calculating-graph',
    },
    {
        path: 'OnboardingPages/ObTrustFeedback',
        route: 'ob-trust-feedback',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/ObUnfortunately2',
        route: 'ob-unfortunately2',
    },
    {
        path: 'OnboardingPages/ObConfidence',
        route: 'ob-confidence',
    },
    {
        path: 'OnboardingPages/ObReason',
        route: 'ob-reason',
    },
    {
        path: 'OnboardingPages/ObReachFeel',
        route: 'ob-reach-feel',
    },
    {
        path: 'OnboardingPages/ObReward',
        route: 'ob-reward',
    },
    {
        path: 'OnboardingPages/ObMotivationFeedback',
        route: 'ob-motivation-feedback',
    },
    {
        path: 'OnboardingPages/ObBadHabitsDes',
        route: 'ob-bad-habits-des',
    },
    {
        path: 'OnboardingPages/ObUnfortunately2Des',
        route: 'ob-unfortunately2-des',
    },
    {
        path: 'OnboardingPages/ObConfidenceDes',
        route: 'ob-confidence-des',
    },
    {
        path: 'OnboardingPages/ObReasonDes',
        route: 'ob-reason-des',
    },
    {
        path: 'OnboardingPages/ObReachFeelDes',
        route: 'ob-reach-feel-des',
    },
    {
        path: 'OnboardingPages/ObRewardDes',
        route: 'ob-reward-des',
    },
    {
        path: 'OnboardingPages/ObMotivationFeedbackDes',
        route: 'ob-motivation-feedback-des',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi1',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi2',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi3',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi4',
    },
    {
        path: 'OnboardingPages/ObGeneralMulti',
        route: 'ob-general-multi5',
    },
    {
        path: 'OnboardingPages/ObCommitment',
        route: 'ob-commitment',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern1',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern2',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern3',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern4',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern5',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern6',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern7',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern8',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern9',
    },
    {
        path: 'OnboardingPages/ObConcern',
        route: 'ob-concern10',
    },
    {
        path: 'OnboardingPages/ObUpsell',
        route: 'ob-upsell',
    },
    {
        path: 'OnboardingPages/ObCurrentEmotionsDes',
        route: 'ob-current-emotions-des',
    },
    {
        path: 'OnboardingPages/ObStressLookDes',
        route: 'ob-stress-look-des',
    },
    {
        path: 'OnboardingPages/ObPositiveDes',
        route: 'ob-positive-des',
    },
    {
        path: 'OnboardingPages/ObUnfortunatelyStressDes',
        route: 'ob-unfortunately-stress-des',
    },
    {
        path: 'OnboardingPages/ObStressTimeDes',
        route: 'ob-stress-time-des',
    },
    {
        path: 'OnboardingPages/ObSleepProblemsDes',
        route: 'ob-sleep-problems-des',
    },
    {
        path: 'OnboardingPages/ObSelftimeDes',
        route: 'ob-selftime-des',
    },
    {
        path: 'OnboardingPages/EmailAccountNew',
        route: 'email-account-new',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryFitLevelNew',
        route: 'feedback-summary-fitlevel-new',
    },
    {
        path: 'OnboardingPages/ObGoalsMono',
        route: 'ob-goals-mono',
    },
    {
        path: 'OnboardingPages/ObHeightCheckbox',
        route: 'ob-height-checkbox',
    },
    {
        path: 'OnboardingPages/ObScale',
        route: 'ob-scale',
    },
    {
        path: 'OnboardingPages/ObFitnessTracker',
        route: 'ob-fitness-tracker',
    },
    {
        path: 'OnboardingPages/ObScientificFeedback',
        route: 'ob-scientific-feedback',
    },
    {
        path: 'OnboardingPages/ObScientificFeedback',
        route: 'ob-scientific-feedback-a',
    },
    {
        path: 'OnboardingPages/ObSleepProblemsFemale',
        route: 'ob-sleep-problems-female',
    },
    {
        path: 'OnboardingPages/ObSleepTime',
        route: 'ob-sleep-time',
    },
    {
        path: 'OnboardingPages/ObSleepTime',
        route: 'ob-sleep-time-a',
    },
    {
        path: 'OnboardingPages/ObTransitionStage',
        route: 'ob-transition-stage',
    },
    {
        path: 'OnboardingPages/ObBenefits',
        route: 'ob-benefits',
    },
    {
        path: 'OnboardingPages/ObSymptomsFemale',
        route: 'ob-symptoms-female',
    },
    {
        path: 'OnboardingPages/ObMood',
        route: 'ob-mood',
    },
    {
        path: 'OnboardingPages/ObCurrentEmotionsFemale',
        route: 'ob-current-emotions-female',
    },
    {
        path: 'OnboardingPages/ObGoalsExp',
        route: 'ob-goals-exp',
    },
    {
        path: 'OnboardingPages/ObGoalsFull',
        route: 'ob-goals-full',
    },
    {
        path: 'OnboardingPages/ObSensitiveAreas',
        route: 'ob-sensitive-areas',
    },
    {
        path: 'OnboardingPages/ObInjuredAreas',
        route: 'ob-injured-areas',
    },
    {
        path: 'OnboardingPages/ObOccasionResultNew',
        route: 'ob-occasion-result-new',
    },
];
