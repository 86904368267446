import { FORM_STYLES } from 'constants/formStyles';

export const BUILT_IN_PAYMENT_METHOD_FORM_STYLES = {
    ...FORM_STYLES,
    submit_button: {
        ...FORM_STYLES.submit_button,
        height: '56px',
        'border-radius': '0',
    },
    card_number: {
        ...FORM_STYLES.card_number,
        input: {
            ...FORM_STYLES.card_number.input,
            height: '56px',
            'border-radius': '0',
        },
    },
    expiry_date: {
        ...FORM_STYLES.expiry_date,
        input: {
            ...FORM_STYLES.expiry_date.input,
            height: '56px',
            'border-radius': '0',
        },
    },
    card_cvv: {
        ...FORM_STYLES.card_cvv,
        input: {
            ...FORM_STYLES.card_cvv.input,
            height: '56px',
            'border-radius': '0',
        },
    },
    zip_code: {
        ...FORM_STYLES.zip_code,
        input: {
            ...FORM_STYLES.zip_code.input,
            height: '56px',
            'border-radius': '0',
        },
    },
};
