// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appScreensSlider {
    width: 100%;
    max-width: calc(560px + var(--default-indent-double));
    margin: 0 auto;
    overflow: hidden;
}

.appScreensSlider .slick-slider {
    padding-bottom: 8px;
}

.appScreensSlider .slick-active .appScreenSlideContent {
    opacity: 1;
}

.appScreensSlider .slick-slide {
    transform: scale(0.8);
    transition: all 0.3s ease;
}

.appScreensSlider .slick-active {
    transform: scale(1);
}

.appScreenSlide {
    width: 200px !important;
    display: flex !important;
    flex-direction: column;
    height: 100%;
}

.appScreenSlideImage {
    flex-shrink: 0;
}

.appScreenSlideContent {
    opacity: 0;

    /* max-width the same as block container width */
    max-width: calc(560px + var(--default-indent-double));
    width: 100vw;
    transform: translateX(-50%);
    margin-left: 50%;
    padding: 24px var(--default-indent);
}
`, "",{"version":3,"sources":["webpack://./src/components/redesigned/payment/AppScreensBlockDes/sliderStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qDAAqD;IACrD,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;IACxB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;;IAEV,gDAAgD;IAChD,qDAAqD;IACrD,YAAY;IACZ,2BAA2B;IAC3B,gBAAgB;IAChB,mCAAmC;AACvC","sourcesContent":[".appScreensSlider {\n    width: 100%;\n    max-width: calc(560px + var(--default-indent-double));\n    margin: 0 auto;\n    overflow: hidden;\n}\n\n.appScreensSlider .slick-slider {\n    padding-bottom: 8px;\n}\n\n.appScreensSlider .slick-active .appScreenSlideContent {\n    opacity: 1;\n}\n\n.appScreensSlider .slick-slide {\n    transform: scale(0.8);\n    transition: all 0.3s ease;\n}\n\n.appScreensSlider .slick-active {\n    transform: scale(1);\n}\n\n.appScreenSlide {\n    width: 200px !important;\n    display: flex !important;\n    flex-direction: column;\n    height: 100%;\n}\n\n.appScreenSlideImage {\n    flex-shrink: 0;\n}\n\n.appScreenSlideContent {\n    opacity: 0;\n\n    /* max-width the same as block container width */\n    max-width: calc(560px + var(--default-indent-double));\n    width: 100vw;\n    transform: translateX(-50%);\n    margin-left: 50%;\n    padding: 24px var(--default-indent);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
