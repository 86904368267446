import { useSelector } from 'react-redux';

import { selectRedesignedFlow } from 'redux/redesignedFlow/selectors';

import { DEFAULT_AGE, getLevel } from '../config';

export const useGetParameters = () => {
    const { configPaymentParams } = useSelector(selectRedesignedFlow);

    return [
        {
            name: 'level',
            title: 'configurablePayment.goalsBlocks.parameters.level',
            value: getLevel(configPaymentParams?.realFitnessLevel).title,
        },
        {
            name: 'type',
            title: 'onboardingFlow.obFeedbackSummaryFitlevelDes.bodyType',
            value: configPaymentParams?.currenBodyTitle || 'onboardingFlow.obBodyTypeCurrent.option4',
        },
        {
            name: 'age',
            title: 'configurablePayment.goalsBlocks.parameters.age',
            value: String(configPaymentParams?.userAge || DEFAULT_AGE),
        },
    ];
};
