import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/user/selectors';

import { EU_COUNTRIES_LIST } from 'constants/countriesList';

export const getCopyrightKey = () => {
    const country = useSelector(selectCountry);

    const isCountryEU = EU_COUNTRIES_LIST.find((el) => el.code === country);

    if (isCountryEU) {
        return 'guarantee.copyright.EU';
    }

    return 'guarantee.copyright';
};
