import { Trans } from 'react-i18next';

import bindGlobalStyles from 'helpers/bindGlobalStyles';

import { Text } from 'libComponents';
import redesignedFontStyles from 'components/redesigned/common/TextDes/styles.module.css';
import { TextDes } from 'components/redesigned/common';

import styles from './styles.module.css';

import { getWhatYouGetList } from './config';

const { cx } = bindGlobalStyles(styles, redesignedFontStyles);

const WhatIncludedNumbersDes = () => {
    const whatYouGetList = getWhatYouGetList();

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <TextDes type="h4" center mb={32}>
                    <Trans i18nKey="onboardingFlow.paymentI1Golf17.whatYouGet.title" />
                </TextDes>

                <ul className={styles.list}>
                    {whatYouGetList.map((item, index) => (
                        <li key={index} className={styles.listItem}>
                            <Text color="text-secondary">
                                <Trans
                                    {...item}
                                    components={{ 1: <span className={cx('redesignedFont', 'listItemNumber')} /> }}
                                />
                            </Text>
                        </li>
                    ))}
                </ul>

                <Text color="text-secondary-subdued">
                    <Trans i18nKey="paymentFlow.paymentDa.whatYouGet.item2" />
                </Text>
            </div>
        </div>
    );
};

export default WhatIncludedNumbersDes;
