import { LS_KEYS } from 'helpers/localStorage';

export const getWhatYouGetList = () => [
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item1',
        context: localStorage.getItem(LS_KEYS.currentBranchName) || '',
    },
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item2',
    },
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item3',
    },
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item4',
    },
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item5',
    },
    {
        i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item6',
        shouldUnescape: true,
        values: { lessThanSign: '&lt;' },
    },
];
