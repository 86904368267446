import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { selectOnboarding } from 'redux/onboadring/selectors';

import { Text, Image } from 'libComponents';
import redesignedTextStyles from 'components/redesigned/common/TextDes/styles.module.css';
import TextDes from 'components/redesigned/common/TextDes';

import styles from './GoalsImageParameters.module.css';

import defaultImage from './img/image.webp';

import { GoalsImageProps } from '../types';

import { useGetParameters } from './config';
import { getTitle } from '../config';

const cx = classNames.bind(styles);

const GoalsImageParameters = ({ titleConfig, femaleImage, maleImage }: GoalsImageProps) => {
    const { gender } = useSelector(selectOnboarding);

    const { t } = useTranslation();

    const title = getTitle(titleConfig, 'goalsImageParameters');
    const parameters = useGetParameters();

    const imageFemale = femaleImage || defaultImage;
    const imageMale = maleImage || defaultImage;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <TextDes type="h4" center mb={24}>
                    <Trans
                        i18nKey={title}
                        components={{ 1: <span className={redesignedTextStyles.highlightedTextColor} /> }}
                    />
                </TextDes>
                <Image src={gender === 'male' ? imageMale : imageFemale} alt="image" className={styles.image} />
                <div className={styles.parameters}>
                    {parameters.map((parameter, index) => (
                        <div key={index} className={cx('parameters__item', `parameters__item--${parameter.name}`)}>
                            <Image alt="icon" src={parameter.icon} maxWidth={20} />
                            <div className={styles.parameters__content}>
                                <Text type="caption" text={t(parameter.title)} />
                                <Text medium text={parameter.value} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GoalsImageParameters;
