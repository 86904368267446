import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';

import { Text, Image } from 'libComponents';

import styles from './styles.module.css';

import stars from './rating.svg';

const cx = classNames.bind(styles);

const RatingRow = ({ isSmallSize, gap = 8, mb = 3 }: { isSmallSize?: boolean; gap?: number; mb?: number }) => {
    const textType = isSmallSize ? 'small-text' : 'large-text';

    return (
        <div className={styles.ratingWrapper} style={{ gap: gap, marginBottom: mb }}>
            <div className={cx('starsWrapper', { starsWrapperSmall: isSmallSize })}>
                <Image alt="stars" src={stars} />
            </div>
            <Text type={textType}>
                <Trans
                    i18nKey="paymentFlow.checkoutGolf14.ratingsBanner.currentRating"
                    components={{
                        1: <Text inline bold type={textType} color="text-main" />,
                    }}
                    values={{ currentRating: `${4.5}+` }}
                />
            </Text>
        </div>
    );
};

export default RatingRow;
