// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --redesign-red-color: #f0384e;
    --redesign-yellow-color: #eeab29;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/redesignedFlow.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gCAAgC;AACpC","sourcesContent":[":root {\n    --redesign-red-color: #f0384e;\n    --redesign-yellow-color: #eeab29;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
