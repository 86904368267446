import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { selectOnboarding } from 'redux/onboadring/selectors';

import { Text, Image } from 'libComponents';
import { useGetParameters } from 'components/redesigned/payment/goalsBlocks/GoalsImage/config';
import { getTitle } from 'components/redesigned/payment/goalsBlocks/config';
import TextDes from 'components/redesigned/common/TextDes';

import styles from './GoalsImage.module.css';

import { GoalsImageProps } from '../types';

import defaultImage from './image.webp';

const cx = classNames.bind(styles);

const GoalsImage = ({ titleConfig, femaleImage, maleImage }: GoalsImageProps) => {
    const { gender } = useSelector(selectOnboarding);

    const { t } = useTranslation();

    const title = getTitle(titleConfig);
    const parameters = useGetParameters();

    const imageFemale = femaleImage || defaultImage;
    const imageMale = maleImage || defaultImage;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <TextDes type="h4" text={t(title)} center mb={24} />
                <div className={styles.parameters}>
                    {parameters.map((parameter, index) => (
                        <div key={index} className={cx('parameters__item', `parameters__item--${parameter.name}`)}>
                            <div className={styles.parameters__content}>
                                <Text type="caption" text={t(parameter.title)} center />
                                <Text medium text={parameter.value} center />
                            </div>
                        </div>
                    ))}
                    <Image
                        src={gender === 'male' ? imageMale : imageFemale}
                        alt="image"
                        maxWidth={212}
                        className={styles.parameters__image}
                    />
                </div>
            </div>
        </div>
    );
};

export default GoalsImage;
