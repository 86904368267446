import { LS_KEYS } from 'helpers/localStorage';

import time from './img/time.webp';
import relax from './img/relax.webp';
import practice from './img/practice.webp';
import movie from './img/movie.webp';
import legal from './img/legal.webp';
import guides from './img/guides.webp';

export const getWhatYouGetList = () => [
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item1',
            context: localStorage.getItem(LS_KEYS.currentBranchName) || '',
        },
        icon: legal,
    },
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item2',
        },
        icon: practice,
    },
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item3',
        },
        icon: relax,
    },
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item4',
        },
        icon: guides,
    },
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item5',
        },
        icon: movie,
    },
    {
        translationOptions: {
            i18nKey: 'whatIncludedNumbersDes.whatYouGet.list.item6',
            shouldUnescape: true,
            values: { lessThanSign: '&lt;' },
        },
        icon: time,
    },
];
