import { HEIGHT_CONSTRAINTS, WEIGHT_CONSTRAINTS } from 'constants/validationConstraints';
import { FitnessGoal } from 'constants/onboardingFlow';
import { IMPERIAL } from 'constants/measureUnits';

import { MeasureSystemType } from 'types/commonInterfaces';

const LEGACY_GOALS = {
    weight_loss: FitnessGoal.LoseWeight,
    be_more_active: FitnessGoal.ImproveHeartHealth,
    endurance_improvement: FitnessGoal.GetFirmAndToned,
};

export const getDefaultWeightAndHeight = (measure: MeasureSystemType) => {
    const { poundMinValue, kilogramMinValue } = WEIGHT_CONSTRAINTS;
    const { singleFeetMinValue, centimetersMinValue } = HEIGHT_CONSTRAINTS;

    const weight = measure === IMPERIAL ? poundMinValue : kilogramMinValue;
    const height = measure === IMPERIAL ? singleFeetMinValue : centimetersMinValue;

    return {
        weight,
        height,
        target_weight: weight,
    };
};

// @ts-ignore TODO: this is a workaround to silence sentry. Need to implement better solution. The problem with redux-persist that keeps old values
export const removeLegacyValues = (goals: FitnessGoal[]) => goals.map((goal) => LEGACY_GOALS[goal] ?? goal);
