import visa from 'assets/img/paymentMethods/visa.webp';
import ssl from 'assets/img/paymentMethods/ssl.png';
import paypal from 'assets/img/paymentMethods/payPal.webp';
import master from 'assets/img/paymentMethods/master.webp';

import { GuarantyConfigType } from './types';

export const DATA: GuarantyConfigType = {
    payments: [
        {
            src: visa,
            alt: 'visa',
        },
        {
            src: master,
            alt: 'master card',
        },
        {
            src: paypal,
            alt: 'paypal',
        },
        {
            src: ssl,
            alt: 'ssl sertificate',
        },
    ],
};
