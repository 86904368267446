import { DEFAULT_HEADER_SETTINGS } from 'redux/redesignedFlow/constants';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import type { RedesignedFlowI } from 'types/redesignedFlow';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: RedesignedFlowI = {
    isRedesignedFlow: false,
    headerSettings: DEFAULT_HEADER_SETTINGS,
    configPaymentParams: {
        currenBodyTitle: null,
        targetBodyTitle: null,
        goalsTitles: [],
        realFitnessLevel: null,
        userAge: null,
    },
};

const redesignedFlow = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_IS_REDESIGNED_FLOW:
            return { ...state, isRedesignedFlow: action.payload };

        case actionTypes.SET_HEADER_SETTINGS:
            return { ...state, headerSettings: { ...action.payload } };

        case actionTypes.SET_CURRENT_BODY_TITLE: {
            return {
                ...state,
                configPaymentParams: { ...state.configPaymentParams, currenBodyTitle: action.payload },
            };
        }

        case actionTypes.SET_TARGET_BODY_TITLE: {
            return {
                ...state,
                configPaymentParams: { ...state.configPaymentParams, targetBodyTitle: action.payload },
            };
        }

        case actionTypes.SET_GOALS_TITLES: {
            return {
                ...state,
                configPaymentParams: { ...state.configPaymentParams, goalsTitles: action.payload },
            };
        }

        case actionTypes.SET_REAL_FITNESS_LEVEL: {
            return {
                ...state,
                configPaymentParams: { ...state.configPaymentParams, realFitnessLevel: action.payload },
            };
        }

        case actionTypes.SET_USER_AGE: {
            return {
                ...state,
                configPaymentParams: { ...state.configPaymentParams, userAge: action.payload },
            };
        }

        default:
            return state;
    }
};

export default redesignedFlow;
