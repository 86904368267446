import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/user/selectors';
import { selectIsLatamOn } from 'redux/payment/selectors';

import useGetAllFreeGuidesPrice from 'hooks/payment/useGetAllFreeGuidesPrice';

import { isCountryWithEnormousPrice } from 'helpers/payment/latam';

import EnormousPriceCard from './components/EnormousPriceCard';
import DefaultCard from './components/DefaultCard';

export default function FreeGuidesCard() {
    const country = useSelector(selectCountry);
    const isLatamOn = useSelector(selectIsLatamOn);

    const price = useGetAllFreeGuidesPrice();

    if (isLatamOn && isCountryWithEnormousPrice(country)) return <EnormousPriceCard price={price} />;

    return <DefaultCard price={price} />;
}
