import { Trans } from 'react-i18next';
import CompoundTimer from 'react-compound-timer';
import { ElementType, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Text } from 'libComponents';

import styles from './Timer.module.css';

import { ReactComponent as Clock } from './img/clock.svg';

type TimerPropsType = {
    initTimer: number;
    timerEnd?: () => void;
    rounded?: boolean;
    timerText: string;
    noMin?: boolean;
    echoTimer?: boolean;
    withImage?: boolean;
    className?: string;
    svgIcon?: ElementType;
};

const cx = classNames.bind(styles);

function Timer({
    initTimer,
    timerEnd,
    rounded,
    timerText,
    noMin,
    echoTimer,
    withImage,
    className,
    svgIcon: SvgIcon = Clock,
}: TimerPropsType) {
    const [isTimerEnds, setIsTimerEnds] = useState(false);

    const timerClass = cx('root', className, {
        timerEnded: isTimerEnds,
        echoTitle: echoTimer,
        rounded,
    });

    useEffect(() => {
        if (initTimer <= 0) setIsTimerEnds(true);
    }, [initTimer]);

    const handleTimerStops = () => {
        timerEnd && timerEnd();

        setIsTimerEnds(true);
    };

    return (
        <div data-locator="timerFrame" className={timerClass}>
            {withImage && <SvgIcon className={styles.icon} />}

            {isTimerEnds ? (
                <Trans i18nKey="Special offer not available" />
            ) : (
                <>
                    <Text color="decorative-c">
                        <Trans i18nKey={timerText} />
                        &nbsp;
                    </Text>
                    <div className={styles.timerBox} data-locator="timer">
                        <CompoundTimer
                            initialTime={initTimer}
                            startImmediately={true}
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 0,
                                    callback: handleTimerStops,
                                },
                            ]}
                        >
                            <CompoundTimer.Minutes formatValue={(value) => `${value === 10 ? value : '0' + value}`} />
                            :
                            <CompoundTimer.Seconds formatValue={(value) => `${value < 10 ? '0' + value : value}`} />
                        </CompoundTimer>
                    </div>
                    {!noMin && (
                        <Text color="decorative-c">
                            &nbsp;
                            <Trans i18nKey="basics.min" />
                        </Text>
                    )}
                </>
            )}
        </div>
    );
}

export default Timer;
