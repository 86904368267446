export const onboardingScreensList = [
    'ob-goals',
    'ob-body-type',
    'ob-body-type-target',
    'ob-target-zones',
    'ob-body-image',
    'ob-activity-level',
    'ob-walking',
    'ob-pushups-squats',
    'ob-yoga-level',
    'ob-bending-knees',
    'ob-meals-feel',
    'ob-sleep',
    'ob-water',
    'ob-diets',
    'ob-height',
    'ob-weight',
    'ob-weight-target',
    'ob-age',
    'ob-tags-a',
    'ob-occasion',
    'ob-occasion-result',
    'ob-medications',
    'ob-unfortunately',
    'ob-restrictive-diet',
    'ob-goals-mix',
    'ob-body-type-mix',
    'ob-body-type-target-mix',
    'ob-pilates-level',
    'ob-familiarity-full',
    'ob-target-zones-full',
    'ob-current-body-type-a',
    'ob-target-body-type-a',
    'ob-target-zones-b',
    'ob-target-zones-c',
    'ob-sex',
    'ob-current-emotions',
    'ob-positive',
    'ob-unfortunately-stress',
    'ob-stress-look',
    'ob-stress-time',
    'ob-sleep-problems',
    'ob-focus',
    'ob-selftime',
    'ob-general',
    'ob-general1',
    'ob-general2',
    'ob-general3',
    'ob-general4',
    'ob-general5',
    'ob-general6',
    'ob-general7',
    'ob-general8',
    'ob-general9',
    'ob-general10',
    'ob-sleep-full',
    'ob-see-intro',
    'ob-see-1',
    'ob-see-2',
    'ob-see-3',
    'ob-height-des',
    'ob-weight-des',
    'ob-weight-target-des',
    'ob-age-des',
    'ob-current-body-type-a-des',
    'ob-target-body-type-a-des',
    'ob-target-zones-c-des',
    'ob-goals-des',
    'ob-body-image-des',
    'ob-walking-des',
    'ob-bending-knees-des',
    'ob-familiarity-full-des',
    'ob-tags-a-des',
    'ob-activity-level-des',
    'ob-pilates-level-des',
    'ob-meals-feel-des',
    'ob-water-des',
    'ob-diets-des',
    'ob-occasion-des',
    'ob-occasion-result-des',
    'ob-pushups-squats-des',
    'ob-sleep-des',
    'ob-yoga-level-des',
    'ob-general-des',
    'ob-general-des1',
    'ob-general-des2',
    'ob-general-des3',
    'ob-general-des4',
    'ob-general-des5',
    'ob-health-conditions',
    'ob-back-issue-config',
    'ob-bad-habits',
    'ob-unfortunately2',
    'ob-confidence',
    'ob-reason',
    'ob-reach-feel',
    'ob-reward',
    'ob-bad-habits-des',
    'ob-unfortunately2-des',
    'ob-confidence-des',
    'ob-reason-des',
    'ob-reach-feel-des',
    'ob-reward-des',
    'ob-general-multi',
    'ob-general-multi1',
    'ob-general-multi2',
    'ob-general-multi3',
    'ob-general-multi4',
    'ob-general-multi5',
    'ob-commitment',
    'ob-concern1',
    'ob-concern2',
    'ob-concern3',
    'ob-concern4',
    'ob-concern5',
    'ob-concern6',
    'ob-concern7',
    'ob-concern8',
    'ob-concern9',
    'ob-concern10',
    'ob-upsell',
    'ob-current-emotions-des',
    'ob-stress-look-des',
    'ob-positive-des',
    'ob-unfortunately-stress-des',
    'ob-stress-time-des',
    'ob-sleep-problems-des',
    'ob-selftime-des',
    'ob-goals-mono',
    'ob-height-checkbox',
    'ob-scale',
    'ob-fitness-tracker',
    'ob-symptoms-female',
    'ob-mood',
    'ob-sleep-problems-female',
    'ob-sleep-time',
    'ob-sleep-time-a',
    'ob-current-emotions-female',
    'ob-transition-stage',
    'ob-goals-exp',
    'ob-goals-full',
    'ob-sensitive-areas',
    'ob-injured-areas',
    'ob-occasion-result-new',
];
