import { useSelector } from 'react-redux';

import { selectUser } from 'redux/user/selectors';
import { selectPayment } from 'redux/payment/selectors';

import { IUsePaymentData } from '../types';

import { CurrentProduct } from 'interfaces/Payments/payments';

const DEFAULT_PRICE = 1;

export function usePaymentData(): IUsePaymentData {
    const { user_id, country } = useSelector(selectUser);
    const { currentProduct, currency, merchantData } = useSelector(selectPayment);

    return {
        merchantData,
        currentProduct: currentProduct as CurrentProduct | null,
        paymentData: {
            userId: user_id,
            payment_method: 1, // probably here always be card method = 1, need check
            // @ts-ignore
            price: currentProduct?.price || DEFAULT_PRICE,
            country,
            currency: currency.name,
        },
    };
}
