import { useTranslation } from 'react-i18next';

import { Button, Text, Image } from 'libComponents';
import { TextDes } from 'components/redesigned';

import styles from './SocialProofsStartsMlnButton.module.css';

import { ReactComponent as Stars } from './img/stars.svg';
import branchRight from './img/branchRight.png';
import branchLeft from './img/branchLeft.png';

const USERS_AMOUNT = 120;
const DOWNLOADS_AMOUNT = 30;

const SocialProofsStartsMlnButton = ({ handleRatingButtonClick }: { handleRatingButtonClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <TextDes
                    type="h4"
                    color="on-primary"
                    center
                    mb={24}
                    text={t('configurablePayment.socialProof1Button.title')}
                />
                <div className={styles.ratings}>
                    <div className={styles.ratings__item}>
                        <Image src={branchLeft} alt="branch" maxWidth={38} />
                        <div className={styles.ratings__content}>
                            <Text
                                bold
                                center
                                text={t('onboardingFlow.RatingBlockDes.userCountText', { count: USERS_AMOUNT })}
                            />
                            <Stars />
                            <Text
                                type="caption"
                                className={styles.ratings__text}
                                center
                                text={t('onboardingFlow.RatingBlockDes.ratingText')}
                            />
                        </div>
                        <Image src={branchRight} alt="branch" maxWidth={38} />
                    </div>
                    <div className={styles.ratings__item}>
                        <Image src={branchLeft} alt="branch" maxWidth={38} />
                        <div className={styles.ratings__content}>
                            <Text
                                type="large-text"
                                medium
                                center
                                text={t('configurablePayment.socialProofStartsMlnButton.downloadsCount', {
                                    count: DOWNLOADS_AMOUNT,
                                })}
                            />
                            <Text
                                type="caption"
                                className={styles.ratings__text}
                                center
                                text={t('configurablePayment.socialProofStartsMlnButton.downloadsTitle')}
                            />
                        </div>
                        <Image src={branchRight} alt="branch" maxWidth={38} />
                    </div>
                </div>

                <Button
                    dataLocator="startPaymentBtn"
                    onClick={handleRatingButtonClick}
                    text="onboardingFlow.paymentI1Golf17.ratingBlock.button"
                    withPulsating
                />
            </div>
        </div>
    );
};

export default SocialProofsStartsMlnButton;
