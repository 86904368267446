import * as actionTypes from './actionTypes';

import { FitnessLevel } from 'constants/onboardingFlow';

import { RedesignedFlowI } from 'types/redesignedFlow';

export function setIsRedesignedFlow(status: boolean) {
    return {
        type: actionTypes.SET_IS_REDESIGNED_FLOW,
        payload: status,
    } as const;
}
export function setHeaderSettings(payload: RedesignedFlowI['headerSettings']) {
    return {
        type: actionTypes.SET_HEADER_SETTINGS,
        payload,
    } as const;
}

export function setCurrentBodyTitle(body_type: string | undefined) {
    return {
        type: actionTypes.SET_CURRENT_BODY_TITLE,
        payload: body_type,
    } as const;
}

export function setTargetBodyTitle(body_type: string | undefined) {
    return {
        type: actionTypes.SET_TARGET_BODY_TITLE,
        payload: body_type,
    } as const;
}

export function setGoalsTitles(payload: string[]) {
    return {
        type: actionTypes.SET_GOALS_TITLES,
        payload,
    } as const;
}

export function setRealFitnessLevel(payload: FitnessLevel) {
    return {
        type: actionTypes.SET_REAL_FITNESS_LEVEL,
        payload,
    } as const;
}

export function setUserAge(payload: number) {
    return {
        type: actionTypes.SET_USER_AGE,
        payload,
    } as const;
}
