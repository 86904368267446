// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successStoriesSlider .slick-slider {
    width: 100%;
    max-width: 927px;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 88px;
}

@media (min-width: 1024px) {
    .successStoriesSlider .slick-slider {
        padding-bottom: 24px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/redesigned/payment/SuccessStoriesBlock/sliderStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".successStoriesSlider .slick-slider {\n    width: 100%;\n    max-width: 927px;\n    overflow: hidden;\n    margin: 0 auto;\n    padding-bottom: 88px;\n}\n\n@media (min-width: 1024px) {\n    .successStoriesSlider .slick-slider {\n        padding-bottom: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
