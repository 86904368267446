import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectRedesignedFlow } from 'redux/redesignedFlow/selectors';
import { selectOnboarding } from 'redux/onboadring/selectors';

import { DEFAULT_WEIGHT } from 'constants/values';

import Convert from 'helpers/Convert';

import { DEFAULT_AGE, getLevel } from '../config';

export const useGetParameters = () => {
    const { target_weight, measureSystem } = useSelector(selectOnboarding);
    const { configPaymentParams } = useSelector(selectRedesignedFlow);

    const { t } = useTranslation();

    const convert = new Convert(measureSystem);
    const targetWeight = convert.getWeight(target_weight || DEFAULT_WEIGHT);
    const unit = convert.getUnit();

    return [
        {
            name: 'level',
            title: 'configurablePayment.goalsBlocks.parameters.level',
            value: t(getLevel(configPaymentParams?.realFitnessLevel).title),
        },
        {
            name: 'weight',
            title: 'paymentFlow.paymentDa.userInfo.titleWeight',
            value: t('configurablePayment.goalsBlocks.parameters.weight.value', { unit, targetWeight }),
        },
        {
            name: 'type',
            title: 'onboardingFlow.obFeedbackSummaryFitlevelDes.bodyType',
            value: t(configPaymentParams?.currenBodyTitle || 'onboardingFlow.obBodyTypeCurrent.option4'),
        },
        {
            name: 'age',
            title: 'configurablePayment.goalsBlocks.parameters.age',
            value: String(configPaymentParams?.userAge || DEFAULT_AGE),
        },
    ];
};
