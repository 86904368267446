import { useSelector } from 'react-redux';

import { selectOnboarding } from 'redux/onboadring/selectors';

import { DEFAULT_WEIGHT } from 'constants/values';

import Convert from 'helpers/Convert';

import weightIcon from './img/weightIcon.svg';
import levelIcon3 from './img/levelIcon3.svg';
import levelIcon2 from './img/levelIcon2.svg';
import levelIcon1 from './img/levelIcon1.svg';

import type { IGetConfig } from './types';

export const useGetConfig: IGetConfig = ({ targetStart }) => {
    const { activityLevel, target_weight, measureSystem } = useSelector(selectOnboarding);

    if (targetStart) {
        const converted = new Convert(measureSystem);
        const userWeight = converted.getWeight(target_weight || DEFAULT_WEIGHT);
        const weightUnit = converted.getUnit();

        let dataActivityLevel = { icon: levelIcon1, title: 'onboardingFlow.yogaLevel.option1.title' };

        switch (activityLevel) {
            case 'sedentary':

            case 'somewhatActive':
                dataActivityLevel = { icon: levelIcon1, title: 'onboardingFlow.yogaLevel.option1.title' };
                break;

            case 'moderatelyActive':

            case 'veryActive':
                dataActivityLevel = { icon: levelIcon2, title: 'onboardingFlow.yogaLevel.option3.title' };
                break;

            case 'highlyActive':
                dataActivityLevel = { icon: levelIcon3, title: 'onboardingFlow.yogaLevel.option4.title' };
                break;

            default:
                break;
        }

        return {
            dataActivityLevel,
            weight: { icon: weightIcon, value: userWeight, unit: weightUnit },
        };
    }

    return {
        dataActivityLevel: null,
        weight: null,
    };
};
