import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { getPriceFromCents } from 'helpers/utils';

import { Box, Text } from 'libComponents';
import { PriceTagPropsType } from 'components/ConfigurablePriceCard/types';

import styles from './PriceTag.module.css';

import { ReactComponent as PriceTriangle } from './img/priceTriangle.svg';

const cx = classNames.bind(styles);

export default function PriceTag({ currencySign, price, isActive, period }: PriceTagPropsType) {
    const { t } = useTranslation();

    const [firstCharAtPrice, lastCharAtPrice = '0'] = getPriceFromCents(price).toString().split('.');
    const lastCharAtPriceResult = lastCharAtPrice?.length === 1 ? `${lastCharAtPrice}0` : lastCharAtPrice;

    const tagPriceColor = isActive ? 'text-secondary' : 'text-secondary-subdued';

    return (
        <Box
            backgroundColor="surface-hovered"
            className={cx('container', { isActive })}
            paddingBottom={2}
            paddingTop={2}
        >
            <PriceTriangle className={styles.icon} />
            <Text className={styles.smallPrices} type="small-text" bold text={currencySign} color={tagPriceColor} />
            <Text type="h2" text={firstCharAtPrice} color={tagPriceColor} />
            <Box className={styles.rightPart}>
                <Text
                    className={styles.smallPrices}
                    text={lastCharAtPriceResult}
                    color={tagPriceColor}
                    type="small-text"
                    bold
                />
                <Text color={tagPriceColor} className={styles.periodLabel} text={t(period)} />
            </Box>
        </Box>
    );
}
