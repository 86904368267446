import * as action from './actions';

import * as actionTypes from './actionTypes';

import { DEFAULT_AGE } from 'constants/values';
import { FitnessLevel } from 'constants/onboardingFlow';

import { getKgWeight, getNumberSystem } from 'helpers/utils';

import { OnboardingStore } from 'types/onboarding/onboardingStore';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export const initialState: OnboardingStore = {
    gender: 'female',
    goal: [],
    current_body: null,
    target_bodytype: null,
    target_zone: null,
    target_weight: null,
    activityLevel: null,
    yogaLevel: null,
    age: DEFAULT_AGE,
    tall: null,
    current_weight: null,
    measureSystem: 'IMPERIAL',
    onboardingScreensList: {},
    urlParams: null,
    occasion: null,
    occasion_date: null,
    fitnessLevel: FitnessLevel.Beginner,
    age_range: {},
    current_mood: null,
    stress_factors: [],
    sleep_problems: [],
    occasionResultConfig: {},
    focus: [],
    body_image: null,
    reason: null,
    reward: null,
    goalGeneral: null,
};

const OnboardingReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_GENDER:
            localStorage.setItem('gender', action.payload);

            return { ...state, gender: action.payload };

        case actionTypes.SET_CURRENT_BODY:
            return { ...state, current_body: action.payload };

        case actionTypes.SET_GOAL:
            return { ...state, goal: action.payload };

        case actionTypes.SET_TARGET_BODY:
            return { ...state, target_bodytype: action.payload };

        case actionTypes.SET_TARGET_ZONE:
            return { ...state, target_zone: action.payload };

        case actionTypes.SET_CURRENT_WEIGHT: {
            const currentWeight = getKgWeight(action.payload);
            const measureSystem = getNumberSystem(action.payload.unit);

            return { ...state, current_weight: currentWeight, measureSystem };
        }

        case actionTypes.SET_TARGET_WEIGHT: {
            const targetWeight = getKgWeight(action.payload);
            const measureSystem = getNumberSystem(action.payload.unit);

            return { ...state, target_weight: targetWeight, measureSystem };
        }

        case actionTypes.SET_ACTIVITY_LVL:
            return { ...state, activityLevel: action.payload };

        case actionTypes.SET_FITNESS_LVL:
            return { ...state, fitnessLevel: action.payload };

        case actionTypes.SET_AGE:
            return { ...state, age: action.payload };

        case actionTypes.SET_TALL: {
            const tall = action.payload.value;
            const measureSystem = getNumberSystem(action.payload.unit);

            return { ...state, tall, measureSystem };
        }

        case actionTypes.SET_AGE_RANGE:
            return { ...state, age_range: action.payload };

        case actionTypes.SET_MEASURE_SYSTEM:
            return { ...state, measureSystem: action.payload };

        case actionTypes.SET_ONBOARDING_SCREENS_LIST:
            return { ...state, onboardingScreensList: action.payload };

        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return { ...state, urlParams: action.payload };
        }

        case actionTypes.SET_OCCASION: {
            return { ...state, occasion: action.payload };
        }

        case actionTypes.SET_OCCASION_DATE: {
            return { ...state, occasion_date: action.payload };
        }

        case actionTypes.SET_CURRENT_MOOD: {
            return { ...state, current_mood: action.payload };
        }

        case actionTypes.SET_STRESS_FACTORS: {
            return { ...state, stress_factors: action.payload };
        }

        case actionTypes.SET_SLEEP_PROBLEMS: {
            return { ...state, sleep_problems: action.payload };
        }

        case actionTypes.SET_OCCASION_RESULT_CONFIG: {
            return { ...state, occasionResultConfig: action.payload };
        }

        case actionTypes.SET_SKIP_ONBOARDING_STATE: {
            return { ...state, ...action.payload };
        }

        case actionTypes.SET_FOCUS: {
            return { ...state, focus: action.payload };
        }

        case actionTypes.SET_BODY_IMAGE: {
            return { ...state, body_image: action.payload };
        }

        case actionTypes.SET_REASON: {
            return { ...state, reason: action.payload };
        }

        case actionTypes.SET_REWARD: {
            return { ...state, reward: action.payload };
        }

        case actionTypes.SET_GOAL_GENERAL: {
            return { ...state, goalGeneral: action.payload };
        }

        default:
            return state;
    }
};

export default OnboardingReducer;
