import { SOLID_ERROR_CODES, SOLID_ERROR_CODES_GROUPS } from 'sentry-utils';
import { SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import { SOLID__NOT_STANDARD_ERROR } from 'constants/payments';

import { Tags } from 'services/Sentry/interfaces';

import { ErrorMessage } from 'components/PaymentMethods/types';

const getErrorCustomTags = (errorData: SdkMessage[MessageType.Error] | SdkMessage[MessageType.Fail]) => {
    const error = errorData?.type === MessageType.Error ? errorData?.details : errorData;

    let customTags: Tags | undefined;

    const { code, message } = error ?? {};

    const getErrorMessage = (message: ErrorMessage) => (typeof message === 'string' ? message : message[0]);
    const getErrorReasonGroup = (errorCode: string) => {
        let reason = SOLID__NOT_STANDARD_ERROR;

        SOLID_ERROR_CODES_GROUPS.forEach(({ name, errorList }) => {
            if (errorList.includes(errorCode)) {
                reason = name;
            }
        });

        return reason;
    };

    if (code && SOLID_ERROR_CODES.includes(code)) {
        const reason = getErrorReasonGroup(code);

        customTags = [
            ['SOLID_ERROR_CODE', code],
            ['reason', reason],
        ];
    }

    if (message) {
        customTags = customTags
            ? [...customTags, ['SOLID_ERROR_MESSAGE', getErrorMessage(message)]]
            : [['SOLID_ERROR_MESSAGE', getErrorMessage(message)]];
    }

    return customTags;
};

export default getErrorCustomTags;
