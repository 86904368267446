import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { SUCCESS_STORIES_LIST } from 'constants/redesignedFlow';

import { getWindowSize } from 'helpers/utils';

import { Image, Text } from 'libComponents';
import redesignedTextStyles from 'components/redesigned/common/TextDes/styles.module.css';
import { TextDes, Slider } from 'components/redesigned';

import styles from './styles.module.css';

import './sliderStyles.css';

const cx = classNames.bind(styles);

const isDesktop = getWindowSize();

const customSliderSettings = {
    dots: !isDesktop,
    autoplay: !isDesktop,
    infinite: !isDesktop,
    slidesToShow: isDesktop ? 3 : 2,
};

const SuccessStoriesBlock = ({
    storiesList = SUCCESS_STORIES_LIST.DEFAULT,
    isAutoPlay,
}: {
    storiesList?: typeof SUCCESS_STORIES_LIST.DEFAULT;
    isAutoPlay?: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <TextDes type="h4" center mb={24} className={styles.containerMaxWidth}>
                <Trans i18nKey="paymentFlow.paymentDa.meetGoals.title" />
            </TextDes>

            <Slider
                className="successStoriesSlider"
                customSettings={{
                    ...customSliderSettings,
                    autoplay: isAutoPlay !== undefined ? isAutoPlay : customSliderSettings.autoplay,
                }}
            >
                {storiesList.map((item, index) => (
                    <div className={styles.slide} key={index}>
                        <Image className={styles.slideImage} alt="User photo" src={item.src} />

                        <div className={styles.slideContent}>
                            <div>
                                <Text bold mb={8}>
                                    <Trans
                                        i18nKey={item.title}
                                        components={{
                                            1: <span className={redesignedTextStyles.highlightedTextColor} />,
                                        }}
                                    />
                                </Text>
                                <Text text={t(item.description)} type="small-text" color="text-secondary" mb={8} />
                            </div>
                            <Text text={t(item.author)} type="small-text" bold />
                        </div>
                    </div>
                ))}
            </Slider>

            <Text
                text={t('onboardingFlow.paymentI1Golf17.successStories.figureDisclaimer')}
                type="caption"
                color="text-secondary-subdued"
                center
                className={cx('containerMaxWidth', 'successStoriesDescription')}
            />
        </div>
    );
};

export default SuccessStoriesBlock;
