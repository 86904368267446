import StoresItems from '../subComponents/StoresItems';
import RatingRow from '../subComponents/RatingRow';

import styles from './SocialProofRatingsShort.module.css';

const SocialProofRatingsShort = () => (
    <div className={styles.ratingsBannerStoresInfo}>
        <RatingRow />
        <StoresItems textType="small-text" />
    </div>
);

export default SocialProofRatingsShort;
