import { useEffect, memo } from 'react';
import Payment from '@solidgate/react-sdk';

import { ISolidPaymentFormProps } from '../types';

function SolidPaymentForm(props: ISolidPaymentFormProps) {
    const { merchantData, setHasPaymentError, isOpenModal } = props;

    useEffect(() => {
        if (merchantData?.signature) {
            setHasPaymentError?.(false);
        }
    }, [merchantData?.signature]);

    return <>{merchantData?.signature && isOpenModal && <Payment {...props} />}</>;
}

function areEqual(prevProps: ISolidPaymentFormProps, nextProps: ISolidPaymentFormProps) {
    return (
        prevProps.merchantData?.signature === nextProps.merchantData?.signature &&
        prevProps.isOpenModal === nextProps.isOpenModal
    );
}

export default memo(SolidPaymentForm, areEqual);
