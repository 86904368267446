import { Trans, useTranslation } from 'react-i18next';

import { REFUND_POLICY } from 'constants/politics';
import { REFUND_POLICY_EVENT_VALUE } from 'constants/analytics';

import { getCopyrightKey } from 'helpers/getCopyrightKey';

import { Text, Image } from 'libComponents';
import { TextDes } from 'components/redesigned';

import styles from './styles.module.css';

import { ReactComponent as Shield } from 'assets/img/items/shield.svg';
import guaranteeWp from './img/guarantee.webp';

import { GuaranteeComponentPropsType, PaymentOptionType } from './types';

import { DATA } from './config';

const MoneyBackGuaranteeDes = ({ handleNextPageClick, className }: GuaranteeComponentPropsType) => {
    const { t } = useTranslation();

    const { payments } = DATA;

    return (
        <div className={className}>
            <div className={styles.container}>
                <Image mb={16} maxWidth={64} src={guaranteeWp} alt="guarantee icon" />

                <TextDes type="h4" mb={16} text={t('paymentFlow.paymentIntroductory.guaranteeTitle')} />

                <Text
                    text={t('onboardingFlow.paymentI1Golf17.guaranteeBlock.disclaimer.text1')}
                    color="text-secondary-subdued"
                    mb={16}
                />
                <Text
                    text={t('onboardingFlow.paymentI1Golf17.guaranteeBlock.disclaimer.text2')}
                    color="text-secondary-subdued"
                    mb={16}
                />
                <Text color="text-secondary-subdued" mb={16}>
                    <Trans
                        i18nKey="onboardingFlow.paymentI1Golf17.guaranteeBlock.disclaimer.text3"
                        components={{
                            1: (
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    href={REFUND_POLICY}
                                    onClick={() => handleNextPageClick?.(REFUND_POLICY_EVENT_VALUE)}
                                    className={styles.linkRefund}
                                    target="_blank"
                                    data-locator="refundPolicyLink"
                                    rel="noopener noreferrer"
                                />
                            ),
                        }}
                    />
                </Text>

                <Text type="caption" color="text-secondary-subdued" text={t(getCopyrightKey())} mb={32} />

                <Text mb={16}>
                    <Trans i18nKey="paymentFlow.paymentIntroductory.guaranteedCheckout">
                        Guaranteed
                        <span className={styles.checkoutWord}>safe</span>
                        checkout
                    </Trans>
                    <div className={styles.shieldIco}>
                        <Shield />
                    </div>
                </Text>

                <div className={styles.imgBox}>
                    {payments.map((item: PaymentOptionType) => (
                        <Image key={item.alt} src={item.src} alt={item.alt} className={styles.imgItem} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MoneyBackGuaranteeDes;
