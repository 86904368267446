import type { TFunction } from 'react-i18next';

import { FitnessLevel } from 'constants/onboardingFlow';

import { getBranchName } from 'helpers/utils';
import { getConfigurableTitle } from 'helpers/localization';

import dot4 from 'assets/img/svg/dot4.svg';
import dot3 from 'assets/img/svg/dot3.svg';
import dot2 from 'assets/img/svg/dot2.svg';
import dot1 from 'assets/img/svg/dot1.svg';

const GOALS_TITLES = {
    lose_weight: 'onboardingFlow.obGoals.option1',
    improve_heart_health: 'onboardingFlow.obGoals.option2',
    get_firm_and_toned: 'onboardingFlow.obGoals.option3',
    relieve_stress: 'onboardingFlow.obGoals.option4',
    // somatic goals
    release: 'onboardingFlow.obGeneral.option1.release',
    weight_loss: 'onboardingFlow.obGoals.option1',
    stress_anxiety: 'onboardingFlow.obGeneral.option3.stress_anxiety',
    pain: 'onboardingFlow.obGeneral.option4.pain',
    flexibility: 'onboardingFlow.obGeneral.option5.flexibility',
    fit_in_my_clothes_better: 'onboardingFlow.obGeneral.option.variant50--dynamicKey',
    Get_back_in_shape_after_giving_birth: 'onboardingFlow.obGeneral.option.variant51--dynamicKey',
};

export const DEFAULT_AGE = '35+';

export const getTitle = (title?: string, component = 'default') => {
    if (!title) return `configurablePayment.goalsBlock.${component}.title.default--dynamicKey`;

    return getConfigurableTitle({
        title: title,
        key: `configurablePayment.goalsBlock.${component}.title.`,
        dynamicFlag: true,
    });
};

export const getLevel = (fitnessLevel: FitnessLevel | null) => {
    switch (fitnessLevel) {
        case FitnessLevel.Experienced:
            return { title: 'configurablePayment.goalsBlocks.parameters.type.option.experienced', icon: dot2 };

        case FitnessLevel.Intermediate:
            return { title: 'onboardingFlow.yogaLevel.option3.title', icon: dot3 };

        case FitnessLevel.Advanced:
            return { title: 'onboardingFlow.yogaLevel.option4.title', icon: dot4 };

        default:
            return { title: 'onboardingFlow.yogaLevel.option1.title', icon: dot1 };
    }
};

export const getGoalsTitles = (goals: string[] | null, t: TFunction<'translation'>) => {
    if (goals?.length) return goals;

    const branchName = getBranchName('default');

    return branchName === 'somatic'
        ? [t(GOALS_TITLES.weight_loss)]
        : [t(GOALS_TITLES.lose_weight), t(GOALS_TITLES.get_firm_and_toned)];
};
