import { Provider } from 'wikr-core-analytics';
import Core from 'testania';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import config from 'config';

import api from 'apiSingleton';

import { selectCountry } from 'redux/user/selectors';
import { GET_DEEP_LINK, SET_COUNTRY, SIGN_UP, UPDATE_INFO, UPDATE_MAILING_PREFERENCES } from 'redux/user/actionTypes';
import {
    disableIsLoading,
    enableIsLoading,
    getDeepLinksSuccess,
    setIsAutologinComplete,
    setIsPaid,
    setToken,
    setUserEmail,
    setUserId,
    signUp,
    signUpFail,
    signUpSuccess,
    updateMailingPreferences,
    updateUserInfo,
    updateUserInfoFail,
} from 'redux/user/actions';
import { setCurrency as setCurrencyToStore } from 'redux/payment/actions';
import {
    selectAge,
    selectMeasureSystem,
    selectOccasionResultConfig,
    selectUrlParams,
} from 'redux/onboadring/selectors';
import { INIT_AUTO_LOGIN } from 'redux/onboadring/actionTypes';
import {
    setCurrentWeight,
    setGender,
    setGoal,
    setOccasionResultConfig,
    setTall,
    setTargetWeight,
} from 'redux/onboadring/actions';

import { CABINET_PATH_REGEX } from 'constants/regex';
import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments/currency';
import { FitnessGoal } from 'constants/onboardingFlow';
import { LTV_COEFFICIENT_KEY } from 'constants/ltv';
import { DEFAULT_LANGUAGE } from 'constants/localization';
import { COUNTRIES_CURRENCY_LIST, DEFAULT_COUNTRY } from 'constants/countriesList';

import sentry from 'services/Sentry/SentryInstance';
import { sendGTMLeadEvent } from 'services/Analytics/trackers/GTMtrakers';
import {
    sendAnalyticButtonSelectEventLabel,
    sendAnalyticLead,
    sendAnalyticRegistration,
    sendAnalyticSignUp,
} from 'services/Analytics';

import { getParamFromUrl, isEmpty } from 'helpers/utils';
import { getOccasionResultConfig } from 'helpers/onboarding/occaisionResult';
import { getCurrentLocalization, getLocalizationFromAvailable } from 'helpers/localization';
import Convert from 'helpers/Convert';
import { getDefaultWeightAndHeight, removeLegacyValues } from './helpers';

import { DeepLinkData, UserInfoResponse } from 'types/user/userApiInterface';
import { GetUserResponse, MeasuringSystemType } from 'types/user/getUser';
import { IGender } from 'types/commonInterfaces';

export function* signUpSaga({
    payload: { payload, setError, toNextPage, sendAnalyticsOnSuccessSubmit },
}: ReturnType<typeof signUp>) {
    try {
        yield put(enableIsLoading());

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const age: number = yield select(selectAge);
        const urlParams: Record<string, string> = yield select(selectUrlParams);
        const measure: MeasuringSystemType = yield select(selectMeasureSystem);
        const country: string = yield select(selectCountry);

        let signUpData = { ...payload, timezone, goal: removeLegacyValues(payload.goal as FitnessGoal[]) };

        if (!payload.target_weight || !payload.height || !payload.weight) {
            signUpData = {
                ...signUpData,
                ...getDefaultWeightAndHeight(measure),
            };
        }

        const data: UserInfoResponse = yield api.user.signUp(signUpData);

        const { token, user_id, email, weight, height, gender } = data;

        sentry.setUser({ user_id, email });

        if (isEmpty(token)) {
            yield call(setError, 'basics.appError.withSupport');

            return;
        }

        Provider.setUserId(user_id).then();
        Provider.setUserEmail(email);

        yield all([put(setToken(token)), put(setUserId(user_id)), put(signUpSuccess(data)), put(setUserEmail(email))]);

        if (country === DEFAULT_COUNTRY) {
            yield call(sendGeoData);
        }

        sendAnalyticLead();
        sendAnalyticRegistration();
        sendAnalyticSignUp();
        sendAnalyticsOnSuccessSubmit?.();
        sendGTMLeadEvent();

        yield put(disableIsLoading());

        yield call(toNextPage);

        Core.calculateLTVCoefficient({
            age: Number(age),
            weight,
            height,
            gender: gender as IGender,
            utm_source: urlParams?.utm_source || 'other',
        })
            .then((coefficient) => {
                localStorage.setItem(LTV_COEFFICIENT_KEY, coefficient);
            })
            .catch((error) => {
                sentry.logError(error, SENTRY_APP, ERROR_LEVELS.ERROR);
            });
    } catch (e) {
        console.error('Api ERROR', e);
        yield put(signUpFail(e));
        yield call(setError, e.error || 'basics.appError.withSupport');
    }
}

export function* updateUserInfoSaga({ payload }: ReturnType<typeof updateUserInfo>) {
    const { data, toNextPage, fieldError } = payload;

    yield put(enableIsLoading());

    try {
        const { email, user_id }: UserInfoResponse = yield api.user.update(data);

        sentry.setUser({ user_id, email });

        yield put(setUserEmail(email));

        yield put(disableIsLoading());

        sendAnalyticButtonSelectEventLabel('registration_page');

        yield call(toNextPage);
    } catch ({ error }) {
        yield put(updateUserInfoFail(error));
        yield call(fieldError, error);
    }
}

export function* getDeepLinksSaga() {
    yield put(enableIsLoading());

    try {
        const link: DeepLinkData = yield api.user.deepLinks();

        yield put(getDeepLinksSuccess(link));
    } catch (error) {
        yield put(disableIsLoading());
        console.error('Api ERROR', error);
    }
}

export function* setUserProfileFromEmail() {
    const token = getParamFromUrl('token');

    if (!token) {
        yield put(setIsAutologinComplete(true));

        return;
    }

    try {
        const languageFromURL = getCurrentLocalization();

        const language = getLocalizationFromAvailable();

        localStorage.setItem('language', language || languageFromURL || DEFAULT_LANGUAGE);

        yield put(setToken(token));

        const {
            email,
            user_id,
            goal,
            gender,
            is_paid,
            height,
            units,
            weight,
            target_weight,
            is_password,
        }: GetUserResponse = yield api.user.getUser();

        if (is_paid && is_password && config.USER_CABINET_URL.match(CABINET_PATH_REGEX)) {
            localStorage.clear();
            yield put({ type: 'RESET_STATE' });

            window.location.href = `${config.USER_CABINET_URL}main/?token=${token}`;
        } else {
            Provider.setUserId(user_id).then();
            Provider.setUserEmail(email);

            yield all([
                put(setUserEmail(email)),
                put(setIsAutologinComplete(true)),
                put(setGoal(goal || null)),
                put(setGender((gender as IGender) || 'female')),
                put(setIsPaid(is_paid)),
                put(setTall({ value: height, unit: units })),
                put(setCurrentWeight({ value: weight, unit: units })),
                put(setTargetWeight({ value: target_weight, unit: units })),
            ]);

            const occasionResultConfig: object = yield select(selectOccasionResultConfig);

            if (isEmpty(occasionResultConfig)) {
                const converter = new Convert();
                const bmi = height && weight ? converter.getBMI(Number(weight), Number(height)) : 1;

                const newOccasionResultConfig = getOccasionResultConfig(weight, target_weight, bmi, units, converter);

                yield put(setOccasionResultConfig(newOccasionResultConfig));
            }
        }
    } catch (e) {
        console.error(e);
        yield put(setIsAutologinComplete(true));
    }
}

export function* setCurrency() {
    const userCountry: string = yield select(selectCountry);

    const filteredCountry = COUNTRIES_CURRENCY_LIST.filter(({ code }) => code === userCountry);

    if (userCountry) {
        // check if country included to config list
        if (filteredCountry.length) {
            const { currency } = filteredCountry.find((item) => item.currency) ?? {
                currency: PAYMENT_DEFAULT_CURRENCY,
            };

            yield put(setCurrencyToStore(currency));
        }
    }

    if (!userCountry || !filteredCountry.length) {
        yield put(setCurrencyToStore(PAYMENT_DEFAULT_CURRENCY));
    }
}

export function* updateMailingPreferencesSaga({ payload }: ReturnType<typeof updateMailingPreferences>) {
    try {
        yield put(enableIsLoading());

        const { data, nextPage } = payload;

        yield api.user.update(data);

        yield put(disableIsLoading());

        yield call(nextPage);
    } catch (e) {
        yield put(disableIsLoading());
        console.error(e);
    }
}

async function sendGeoData() {
    try {
        await api.user.geoData();
    } catch (error) {
        console.error(error);
    }
}

export const userSagas = [
    takeLatest(SIGN_UP, signUpSaga),
    takeLatest(UPDATE_INFO, updateUserInfoSaga),
    takeLatest(GET_DEEP_LINK, getDeepLinksSaga),
    takeLatest(INIT_AUTO_LOGIN, setUserProfileFromEmail),
    takeLatest(SET_COUNTRY, setCurrency),
    takeLatest(UPDATE_MAILING_PREFERENCES, updateMailingPreferencesSaga),
];
