import { useSelector } from 'react-redux';

import { selectCurrency } from 'redux/payment/selectors';

import { PRODUCTS_BLOCK_IDENTIFIER } from 'constants/configurablePaymentScreen';

import { useGetCardsConfigure } from './hooks';

import ProductsBlockDes from 'components/redesigned/payment/ProductsBlockDes';

import styles from './styles.module.css';

import { ProductsControlButtonDesType } from './types';

const ProductsControlButtonDes = (props: ProductsControlButtonDesType) => {
    const { sign } = useSelector(selectCurrency);
    const { products, weekwording } = props;

    const configuredCardsList = useGetCardsConfigure(products, weekwording);

    return (
        <div data-attr={PRODUCTS_BLOCK_IDENTIFIER} id="prices-wrapper" data-locator="plansContainer">
            <ProductsBlockDes
                title="productsControlButtonDes.title"
                configuredCardsList={configuredCardsList}
                currencySign={sign}
                timerClassName={styles.productControlButtonsDesTimer}
                className={styles.productControlButton}
                {...props}
            />
        </div>
    );
};

export default ProductsControlButtonDes;
