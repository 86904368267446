import item3 from 'assets/img/photos/successStories/item3.webp';
import item2 from 'assets/img/photos/successStories/item2.webp';
import item1 from 'assets/img/photos/successStories/item1.webp';

export enum ConfigScreenColors {
    BLACK = 'black',
    GRAY = 'gray',
    WHITE = 'white',
}

export const DEFAULT_SCREEN_COLOR: ConfigScreenColors = ConfigScreenColors.BLACK;

export const COLOR_THEME_MAP: Record<ConfigScreenColors, 'text-main' | 'text-secondary' | 'surface-default'> = {
    [ConfigScreenColors.BLACK]: 'text-main',
    [ConfigScreenColors.GRAY]: 'text-secondary',
    [ConfigScreenColors.WHITE]: 'surface-default',
};

export const SUCCESS_STORIES_LIST = {
    DEFAULT: [
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.description',
            src: item1,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.description',
            src: item2,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.description',
            src: item3,
        },
    ],
    MALE: [
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item3.description',
            src: item3,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item1.description',
            src: item1,
        },
        {
            title: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.title',
            author: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.author',
            description: 'onboardingFlow.paymentI1Golf17.successStories.list.item2.description',
            src: item2,
        },
    ],
};
