import { useTranslation } from 'react-i18next';

import { Button, Image, Text } from 'libComponents';
import { TextDes } from 'components/redesigned';

import styles from './styles.module.css';

import rating from './img/rating.webp';

const SocialProof1Button = ({ handleRatingButtonClick }: { handleRatingButtonClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.ratingWrapper}>
                <div className={styles.imageWrapper}>
                    <Image center src={rating} alt="5 stars amount" maxWidth={328} />

                    <Text className={styles.yogaAppText} center type="large-text" bold text={t('basics.yogaApp')} />
                </div>

                <TextDes
                    type="h4"
                    color="on-primary"
                    center
                    mb={12}
                    text={t('configurablePayment.socialProof1Button.title')}
                />

                <Text
                    type="small-text"
                    color="text-secondary-subdued"
                    center
                    mb={26}
                    text={t('onboardingFlow.paymentI1Golf17.ratingBlock.disclaimer')}
                />

                {handleRatingButtonClick && (
                    <Button
                        dataLocator="startPaymentBtn"
                        onClick={handleRatingButtonClick}
                        text="onboardingFlow.paymentI1Golf17.ratingBlock.button"
                        withPulsating
                    />
                )}
            </div>
        </div>
    );
};

export default SocialProof1Button;
