import classNames from 'classnames/bind';

import { Text } from 'libComponents';

import styles from './styles.module.css';

import { ITextDes } from './types';

const cx = classNames.bind(styles);

const TextDes = (props: ITextDes) => {
    const classNames = cx('redesignedFont', props.className, {
        capitalise: props.capitalise,
    });

    return <Text {...props} className={classNames} />;
};

export default TextDes;
