import { IOption } from 'wikr-core-components';
import { isPilatesBranch } from 'helpers/testania';

// We can distinguish between the old and new flows only by the testName which includes 'redesign__Test' fragment
import { LS_KEYS } from 'helpers/localStorage';

import { IOptionDes } from 'components/redesigned/types';

export const isRedesignedFlow = (testName: string) => testName.includes('redesign__Test');

export const getActivityLevel = () => {
    const branchName = localStorage.getItem(LS_KEYS.currentBranchName);
    const isPilates = isPilatesBranch(branchName);

    return `onboardingFlow.redesignedFlow.level.${isPilates ? 'pilates' : 'yoga'}Level--dynamicKey`;
};

export const getCurrentBodyAnalyticValue = (value: string) => {
    switch (value) {
        case 'Overweight':

        case 'Obese':
            return 'Extra';

        default:
            return value;
    }
};

export const getOptionTitleByValue = (optionData: (IOption | IOptionDes)[], uniqValue?: string) =>
    optionData.find((option) => option.value === uniqValue)?.title;

export const getGoalOptionTitleByValue = (optionData: (IOption | IOptionDes)[], values: string | string[]) => {
    const optionsTitles: string[] = [];

    if (Array.isArray(values)) {
        optionData.map((option) => {
            if (values.includes(option.value) && option.title) optionsTitles.push(option.title);
        });
    } else {
        const title = optionData.find((option) => option.value === values)?.title;

        title && optionsTitles.push(title);
    }

    return optionsTitles;
};
