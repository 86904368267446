import { getLanguage } from 'helpers/utils';

import { FitnessLevel, FitnessGoal } from './onboardingFlow';

export const DEFAULT_USER_PAYLOAD = {
    branch_name: 'default',
    current_body: 'plump',
    date_of_birth: '1957-01-01',
    email: 'test@gmail.com',
    fitness_level: FitnessLevel.Intermediate,
    gender: 'male',
    goal: [FitnessGoal.ImproveHeartHealth],
    height: 140,
    localization: getLanguage(),
    occasion: 'wedding',
    problem_zones: ['belly'],
    target_bodytype: 'fit',
    target_weight: 88,
    test_name: '',
    units: 'metric',
    weight: 77,
};

export const GA_USER_ID = '_ga';
