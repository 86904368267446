import { useTranslation } from 'react-i18next';

import { Text } from 'libComponents';
import StoresItems from '../subComponents/StoresItems';
import RatingRow from '../subComponents/RatingRow';
import AppInfo from '../subComponents/AppInfo';

import styles from './SocialProofRatingsFull.module.css';

const SocialProofRatingsFull = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <StoresItems />
            <div className={styles.ratingsBannerStoresInfo}>
                <RatingRow isSmallSize />
                <AppInfo />
                <Text
                    text={t('configurablePayment.socialProofRatingsFull.downloadsTitle')}
                    color="text-secondary-subdued"
                    type="caption-smallest"
                />
            </div>
        </div>
    );
};

export default SocialProofRatingsFull;
