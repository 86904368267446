import { useTranslation } from 'react-i18next';

import { Image, Text } from 'libComponents';
import { TextDes, Slider } from 'components/redesigned';

import styles from './styles.module.css';
import './sliderStyles.css';

import { useGetAppScreens } from './config';

const customSliderSettings = {
    dots: true,
    autoplay: true,
    infinite: true,
};

const AppScreensBlockDes = ({ isWallPilates = false }: { isWallPilates?: boolean }) => {
    const { t } = useTranslation();

    const appScreenList = useGetAppScreens(isWallPilates);

    return (
        <div>
            <TextDes
                text={t('onboardingFlow.paymentI1Golf17.sliderTitle')}
                type="h4"
                center
                className={styles.containerMaxWidth}
            />

            <Slider className="appScreensSlider" customSettings={customSliderSettings}>
                {appScreenList.map((item, index) => (
                    <div className="appScreenSlide" key={index}>
                        <Image className="appScreenSlideImage" alt="User photo" src={item.src} />

                        <div className="appScreenSlideContent">
                            <Text text={t(item.title)} type="large-text" color="text-secondary" center medium mb={4} />
                            <Text text={t(item.description)} type="small-text" color="text-secondary" center />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default AppScreensBlockDes;
