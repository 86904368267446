import { Box } from 'libComponents';

import styles from './EasyComponentBasedCheckbox.module.css';

export default function EasyComponentBasedCheckbox({ isChecked = false }: { isChecked?: boolean }) {
    return (
        <Box
            className={styles.container}
            borderColor={isChecked ? 'primary-default' : 'border-default'}
            backgroundColor={isChecked ? 'surface-main' : 'surface-secondary'}
        >
            <Box backgroundColor={isChecked ? 'primary-default' : 'surface-secondary'} />
        </Box>
    );
}
