import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { REFUND_POLICY } from 'constants/politics';

import { getTrialFullPrice } from 'helpers/payment/utils';

import { Button, Image, Text } from 'libComponents';
import Timer from 'components/Timer';
import { TextDes } from 'components/redesigned';
import PaymentDisclaimer from 'components/PaymentDisclaimer';
import OLD_ConfigurablePriceCard from 'components/OLD_ConfigurablePriceCard';
import FreeGuidesCard from 'components/FreeGuidesCard';
import ConfigurablePriceCard from 'components/ConfigurablePriceCard';
import AccessOnAnyDevice from 'components/AccessOnAnyDevice';

import styles from './styles.module.css';

import { ReactComponent as WhiteClock } from './img/white_clock.svg';

import type { ProductsBlockPropsType } from './types';

import { useGetConfig } from './config';

const cx = classNames.bind(styles);

const ProductsBlockDes = ({
    title,
    id,
    isActiveTimer,
    timerTime,
    products,
    timerEndHandler,
    handleCurrentProduct,
    activeCardID,
    customTimerText,
    handlePaymentClick,
    paymentTypeData: { currentPaymentType, fullPrice, paymentLabel, trialPrice },
    currencySign,
    configuredCardsList,
    customDisclaimer,
    isTrial,
    isProcessingFee,
    isAccessOnAnyDevice,
    isFreeGuides = false,
    trialProductPrice,
    targetStart = false,
    timerClassName,
    className,
    isGetMyPlanBtnVisible = true,
}: ProductsBlockPropsType) => {
    const { t } = useTranslation();

    const { weight, dataActivityLevel } = useGetConfig({ targetStart });

    const timerClasses = cx('timerContainer', timerClassName);

    const fullPriceDisclaimer = () => {
        if (isTrial && trialProductPrice) return trialProductPrice;

        if (isTrial)
            return getTrialFullPrice({
                paymentProducts: products,
                currentProduct: products.find(({ id }) => activeCardID === id),
            });

        return fullPrice;
    };

    return (
        <div className={cx('container', className)}>
            <TextDes type="h4" center bold mb={15}>
                <Trans i18nKey={title} components={{ 1: <br /> }} />
            </TextDes>

            {targetStart && weight && dataActivityLevel && (
                <div className={styles.userInfo}>
                    <div className={styles.userInfoItem}>
                        <Text
                            type="caption"
                            color="text-secondary-subdued"
                            mb={4}
                            text={t('paymentFlow.paymentDa.userInfo.titleWeight')}
                        />
                        <div className={styles.userInfoData}>
                            <Image
                                src={weight.icon || ''}
                                alt="weightIcon"
                                maxWidth={24}
                                className={styles.userInfoIcon}
                            />
                            <Text type="large-text" medium text={`${weight.value} ${t(`${weight.unit}`)}`} />
                        </div>
                    </div>
                    <div className={styles.userInfoItem}>
                        <Text
                            type="caption"
                            color="text-secondary-subdued"
                            mb={4}
                            text={t('paymentFlow.paymentDa.userInfo.titleLevel')}
                        />
                        <div className={styles.userInfoData}>
                            <Image
                                alt="weightIcon"
                                maxWidth={24}
                                src={dataActivityLevel.icon}
                                className={styles.userInfoIcon}
                            />
                            <Text type="large-text" medium text={t(dataActivityLevel.title)} />
                        </div>
                    </div>
                </div>
            )}

            {isActiveTimer && timerTime > 0 && (
                <Timer
                    timerText={customTimerText || 'timer.defaultText'}
                    withImage
                    svgIcon={WhiteClock}
                    initTimer={timerTime}
                    timerEnd={timerEndHandler}
                    className={timerClasses}
                />
            )}

            <div className={styles.productsBlockWrapper}>
                <div className={styles.pricesWrapper} id={id || 'prices-wrapper'} data-locator="plansContainer">
                    {configuredCardsList
                        ? configuredCardsList?.map((item, index) => (
                              <ConfigurablePriceCard
                                  key={item.id}
                                  isDiscount={isActiveTimer}
                                  currencySign={currencySign}
                                  isCardActive={item.id === activeCardID}
                                  changeHandler={(withAnalytic) => handleCurrentProduct(products[index], withAnalytic)}
                                  dataLocator={`product${index}`}
                                  isProcessingFee={isProcessingFee}
                                  priceCardClass={styles.redesignedPriceCard}
                                  {...item}
                              />
                          ))
                        : products.map((product, index) => (
                              <OLD_ConfigurablePriceCard
                                  setPaymentPrice={(data, withAnalytic) => handleCurrentProduct(data, withAnalytic)}
                                  key={product.id}
                                  index={index}
                                  currencySign={currencySign}
                                  activeCardID={activeCardID}
                                  recurrentDesign
                                  currentProduct={product}
                              />
                          ))}
                    {isFreeGuides && <FreeGuidesCard />}
                </div>

                {isAccessOnAnyDevice && <AccessOnAnyDevice />}

                <a href={REFUND_POLICY} data-locator="refundPolicyLink" target="_blank" rel="noopener noreferrer">
                    <Text
                        type="medium-text"
                        medium
                        center
                        mb={16}
                        className={styles.guaranteeLink}
                        text={t('paymentFlow.paymentDa.guaranteeText')}
                    />
                </a>

                {isGetMyPlanBtnVisible && (
                    <Button
                        dataLocator="startPaymentBtn"
                        onClick={handlePaymentClick}
                        text="basics.getMyPlanBtn"
                        withPulsating
                        mb={24}
                    />
                )}
            </div>

            <PaymentDisclaimer
                currentPaymentType={currentPaymentType}
                customDisclaimer={customDisclaimer}
                paymentLabel={paymentLabel}
                isProcessingFee={isProcessingFee}
                timerTime={timerTime}
                isTrial={isTrial}
                fullPrice={fullPriceDisclaimer()}
                trialPrice={trialPrice}
            />
        </div>
    );
};

export default ProductsBlockDes;
