import { useTranslation } from 'react-i18next';

import { BUILT_IN_PAYMENT_BUTTONS_DATA_ATTR } from '../constants';

import { Text } from 'libComponents';
import { BuiltInVerticalPaymentMethodsPropsType } from 'components/VerticalPaymentMethods/BuiltInVerticalPaymentMethods/types';
import { BuiltInVerticalPaymentMethods } from 'components/VerticalPaymentMethods';

import styles from './styles.module.css';

const PaymentsButtonsHorizontalDes = (props: BuiltInVerticalPaymentMethodsPropsType) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container} data-attr={BUILT_IN_PAYMENT_BUTTONS_DATA_ATTR}>
            <Text medium center text={t('paymentFlow.paymentModalC.title')} type="large-text" mb={16} />

            <BuiltInVerticalPaymentMethods {...props} isBuiltInPaymentMethodHorizontal />
        </div>
    );
};

export default PaymentsButtonsHorizontalDes;
