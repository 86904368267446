import Slider, { Settings } from 'react-slick';
import { memo, ReactNode } from 'react';

import './SliderCustom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings: Settings = {
    centerMode: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    pauseOnHover: true,
    arrows: false,
    dots: false,
    variableWidth: true,
    autoplay: false,
    infinite: true,
    useCSS: true,
};

const SimpleSlider = ({
    children,
    className,
    customSettings,
}: {
    children: ReactNode;
    className?: string;
    customSettings?: Settings;
}) => (
    <div className={`sliderWrapper  ${className}`}>
        <Slider {...settings} {...customSettings}>
            {children}
        </Slider>
    </div>
);

export default memo(SimpleSlider);
