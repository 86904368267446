export const FORM_STYLES = {
    submit_button: {
        // TODO: vars for color
        'background-color': '#FCC419',
        'font-size': '16px',
        'font-weight': 'bold',
        'border-radius': '0',
        'font-family': 'Roboto,sans-serif',
        color: '#212121',
        ':hover': {
            'background-color': '#EEBD29',
        },
        '.title-icon': {
            display: 'none',
        },
    },
    card_number: {
        input: {
            background: '#fff',
            border: '1px solid #D4DAE0',
            'border-radius': '12px 12px 0 0',
            color: '#454E57',
        },
        '.label': {
            display: 'none',
        },
        '.brand-icon': {
            top: '13px',
        },
    },
    expiry_date: {
        input: {
            background: '#fff',
            border: '1px solid #D4DAE0',
            'border-radius': '0 0 0 12px',
            color: '#454E57',
            ':focus': {
                'border-color': '#8395a7',
            },
        },
        '.label': {
            display: 'none',
        },
    },
    card_cvv: {
        input: {
            background: '#fff',
            border: '1px solid #D4DAE0',
            'border-radius': '0 0 12px 0',
            color: '#454E57',
            ':focus': {
                'border-color': '#8395a7',
            },
        },
        '.label': {
            display: 'none',
        },
        '.tooltip-icon': {
            display: 'none',
        },
    },

    zip_code: {
        input: {
            background: '#fff',
            border: '1px solid #D4DAE0',
            'border-radius': '12px',
            color: '#454E57',
            ':focus': {
                'border-color': '#8395a7',
            },
            'margin-bottom': '20px',
        },
        '.label': {
            color: '#474E56',
            'margin-bottom': '4px',
            'font-size': '12px !important',
            'font-weight': 500,
            'line-height': '20px',
        },
        '.tooltip-icon': {
            display: 'none',
        },
        '.error-text': {
            'margin-top': '-20px',
            'margin-bottom': '20px',
        },
    },
};
