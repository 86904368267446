import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';

import ReviewDes from 'components/ReviewDes';

import styles from './ReviewSliderDes.module.css';

import { IReview } from 'types/pages/onboardingPages/commonTypes';
import { IReviewSliderProps } from './types';

const ReviewSliderDes = ({ commentsData, sliderSettings }: IReviewSliderProps) => (
    <Slider className={styles.sliderWrapper} {...sliderSettings}>
        {commentsData?.map((item: IReview, index: number) => (
            <ReviewDes key={index} author={item.author} text={item.text} />
        ))}
    </Slider>
);

export default ReviewSliderDes;
