import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';

import { getPriceFromCents } from 'helpers/utils';
import { getSubscriptionTitle } from 'helpers/subscriptions';

import Text from 'components/Text';

import styles from './OLD_ConfigurablePriceCard.module.css';

import { ReactComponent as PriceTag } from './img/priceTag.svg';

import { CurrentProduct } from 'types/payments/payments';

const cx = classNames.bind(styles);

type OLD_ConfigurablePriceCardPropsType = {
    index: number;
    currencySign?: string;
    recurrentDesign?: boolean;
    currentProduct: CurrentProduct;
    activeCardID: string;
    setPaymentPrice: (productData: CurrentProduct, withAnalytic?: boolean) => void;
};

const OLD_ConfigurablePriceCard = ({
    setPaymentPrice,
    recurrentDesign,
    currencySign,
    currentProduct,
    index,
    activeCardID,
}: OLD_ConfigurablePriceCardPropsType) => {
    const { period, price_per_state, start_price_per_state, id, is_preselected, product_state } = currentProduct;

    const periodType = product_state?.replace('_', ' ');

    const firstCharAtPrice = getPriceFromCents(price_per_state).toString().split('.')[0];
    const lastCharAtPrice = getPriceFromCents(price_per_state).toString().split('.')[1] || ['0'];
    const startPrice = (start_price_per_state / 100).toFixed(2);

    const lastCharAtPriceResult = lastCharAtPrice?.length === 1 ? `${lastCharAtPrice}0` : lastCharAtPrice;

    const getPriceTag = (price: string) => {
        const devidetPrice = price.toString().split('.');

        if (recurrentDesign && devidetPrice.length === 2) {
            return (
                <p className={styles.planPrice}>
                    {currencySign}
                    <span className={styles.priceBigPart}>
                        <PriceTag />
                        {devidetPrice[0]}
                    </span>
                    {devidetPrice[1]}
                    <p className={styles.planPerWeek}>
                        <Trans i18nKey={periodType} />
                    </p>
                </p>
            );
        }

        return (
            <p className={styles.planPrice}>
                {currencySign} {price}
            </p>
        );
    };

    return (
        <label
            className={cx('planItem', { recurrent: recurrentDesign, second: recurrentDesign && is_preselected })}
            onClick={() => setPaymentPrice(currentProduct, true)}
        >
            {recurrentDesign && is_preselected && (
                <Text caption>
                    <span className={styles.mostPopular}>
                        <Trans i18nKey="paymentFlow.paymentIntroductory.mostPopular" />
                    </span>
                </Text>
            )}
            <input
                type="radio"
                name="plan"
                className={cx('planInput', {
                    planInputChecked: id === activeCardID,
                })}
                data-locator={`product${index}`}
            />
            <div className={styles.planBox}>
                <div className={styles.planLeft}>
                    <Text medium bold>
                        <span className={styles.planLeftPlanName}>
                            <Trans
                                i18nKey="paymentFlow.priceCard.period"
                                count={+getSubscriptionTitle(period)?.count}
                                tOptions={{ context: getSubscriptionTitle(period)?.context }}
                            />
                        </span>
                    </Text>
                </div>
                <div className={styles.planRight}>
                    {Boolean(start_price_per_state) && (
                        <p className={styles.planOldPrice}>
                            {start_price_per_state && `${currencySign} ${startPrice}`}
                        </p>
                    )}
                    {getPriceTag(`${firstCharAtPrice}.${lastCharAtPriceResult}`)}
                </div>
            </div>
        </label>
    );
};

export default OLD_ConfigurablePriceCard;
