import { Trans } from 'react-i18next';

import { Text } from 'libComponents';

import styles from './styles.module.css';

const AppInfo = ({ isBigText }: { isBigText?: boolean }) => {
    const textType = isBigText ? 'large-text' : 'small-text';

    return (
        <div className={styles.appInfo}>
            <Text type={textType}>
                <strong>#1</strong> <Trans i18nKey="basics.yogaApp" />
            </Text>
            <div className={styles.appInfo__divider} />
            <Text type={textType}>
                <Trans
                    i18nKey="paymentFlow.checkoutGolf14.ratingsBanner.usersAmount"
                    components={{
                        1: <strong />,
                    }}
                    values={{ usersAmount: '30M+' }}
                />
            </Text>
        </div>
    );
};

export default AppInfo;
