import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import classNames from 'classnames/bind';

import { Text, Box, Image } from 'libComponents';

import styles from './PayPalOption.module.css';

import paypalImg from 'assets/img/paymentMethods/payPalSmall.webp';

import type { IPayPalOption } from './types';

const cx = classNames.bind({ ...styles });

const PayPalOption = ({
    withNoSaferStringAppear = false,
    disclaimer = '',
    isPayPal,
    titleTop = false,
    buttonClass,
    disclaimerDataLocator,
}: IPayPalOption) => {
    const { t } = useTranslation();

    const wrapperStyles = cx({
        payPalBtnWrapperDefault: !isPayPal,
        payPalBtnWrapper: isPayPal,
        payPalBtnMarginBottom: titleTop,
    });

    const payPalButtonClasses = cx('payPal', buttonClass);

    return (
        <div data-option="paypal" className={wrapperStyles}>
            {!withNoSaferStringAppear && titleTop && (
                <Text type="caption" mb={8} text={t('paymentFlow.paymentModalC.paypalDescription')} bold center />
            )}
            {Boolean(disclaimer) && (
                <Box paddingTop={20}>
                    <Text
                        type="caption-small"
                        center
                        color="text-secondary"
                        mb={20}
                        text={disclaimer}
                        dataLocator={disclaimerDataLocator}
                    />
                </Box>
            )}
            <div data-locator="paypalBtn" id="paypal-button" className={payPalButtonClasses}>
                <Image alt="paypal" src={paypalImg} className={styles.payPalImage} />
                <Text text="Buy Now" />
            </div>
            {!withNoSaferStringAppear && !titleTop && (
                <Text
                    type="caption"
                    className={styles.hint}
                    text={t('paymentFlow.paymentModalC.paypalDescription')}
                    bold
                    center
                />
            )}
        </div>
    );
};

export default memo(PayPalOption);
