// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderWrapper .slick-slider,
.sliderWrapper .slick-slider .slick-list,
.sliderWrapper .slick-slider .slick-track,
.sliderWrapper .slick-slider .slick-slide {
    display: flex;
    flex-grow: 1;
}

/* slide start */
.sliderWrapper .slick-slide {
    height: 100%;
}

/* slide end */

/* dots start */
.sliderWrapper .slick-slider .slick-dots {
    bottom: 0;
}

.sliderWrapper .slick-slider .slick-dots li {
    width: unset;
    height: unset;
}

.sliderWrapper .slick-slider .slick-dots li button:before {
    content: none;
}

.sliderWrapper .slick-slider .slick-dots li button {
    height: 8px;
    width: 8px;
    background-color: var(--surface-secondary);
    border-radius: 4px;
    padding: 0;
}

.sliderWrapper .slick-slider .slick-dots li button:hover {
    background: var(--text-main-secondary);
}

.sliderWrapper .slick-slider .slick-dots .slick-active button {
    width: 20px;
    background: var(--text-main-secondary);
}

/* dots end */
`, "",{"version":3,"sources":["webpack://./src/components/redesigned/payment/SliderDes/SliderCustom.css"],"names":[],"mappings":"AAAA;;;;IAII,aAAa;IACb,YAAY;AAChB;;AAEA,gBAAgB;AAChB;IACI,YAAY;AAChB;;AAEA,cAAc;;AAEd,eAAe;AACf;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,0CAA0C;IAC1C,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,sCAAsC;AAC1C;;AAEA,aAAa","sourcesContent":[".sliderWrapper .slick-slider,\n.sliderWrapper .slick-slider .slick-list,\n.sliderWrapper .slick-slider .slick-track,\n.sliderWrapper .slick-slider .slick-slide {\n    display: flex;\n    flex-grow: 1;\n}\n\n/* slide start */\n.sliderWrapper .slick-slide {\n    height: 100%;\n}\n\n/* slide end */\n\n/* dots start */\n.sliderWrapper .slick-slider .slick-dots {\n    bottom: 0;\n}\n\n.sliderWrapper .slick-slider .slick-dots li {\n    width: unset;\n    height: unset;\n}\n\n.sliderWrapper .slick-slider .slick-dots li button:before {\n    content: none;\n}\n\n.sliderWrapper .slick-slider .slick-dots li button {\n    height: 8px;\n    width: 8px;\n    background-color: var(--surface-secondary);\n    border-radius: 4px;\n    padding: 0;\n}\n\n.sliderWrapper .slick-slider .slick-dots li button:hover {\n    background: var(--text-main-secondary);\n}\n\n.sliderWrapper .slick-slider .slick-dots .slick-active button {\n    width: 20px;\n    background: var(--text-main-secondary);\n}\n\n/* dots end */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
