import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { processingFeeConvertor } from 'helpers/utils';
import { getSubscriptionTitle } from 'helpers/subscriptions';

import { Box, Text } from 'libComponents';

import base from 'assets/styles/Common.module.css';
import styles from './DefaultCard.module.css';

import { GlobalStylesTypes } from 'types/commonInterfaces';
import { ConfigurablePriceCardPropsType } from '../../types';

import PriceTag from './PriceTag';
import EasyComponentBasedCheckbox from '../EasyComponentBasedCheckbox';
import CardLabel from '../CardLabel';

const globalStyles: GlobalStylesTypes = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

export default function DefaultCard({
    changeHandler,
    currencySign,
    isCardActive,
    label,
    pricePerState,
    oldPricePerState,
    perState,
    period,
    isPopular,
    isBestValue,
    isDiscount,
    withFullPrice,
    fullPrice,
    startFullPrice,
    isTrial,
    trial,
    cardLabel,
    dataLocator,
    isProcessingFee,
    periodTitle,
    priceCardClass,
}: ConfigurablePriceCardPropsType) {
    const { t } = useTranslation();

    const getPeriodTitleData = () => {
        if (periodTitle) return periodTitle;

        const periodType = isTrial ? trial : period;

        const key = isTrial ? 'paymentFlow.priceCard.trial' : 'paymentFlow.priceCard.period';
        const { count, context } = getSubscriptionTitle(periodType, isTrial);

        return t(key, { count: Number(count), context });
    };

    return (
        <label className={styles.priceCard} data-locator={dataLocator || 'product'}>
            {cardLabel && (
                <Text
                    medium
                    mb={4}
                    text={t(cardLabel)}
                    type="caption"
                    color="text-secondary"
                    className={styles.labelAboveCard}
                />
            )}
            <div
                className={cx('priceCardBodyWrapper', priceCardClass, {
                    priceCardBodyWrapperActive: isCardActive,
                    priceCardBodyWrapperWithLabel: isPopular || isBestValue,
                })}
            >
                <input
                    type="radio"
                    className={styles.priceCardInput}
                    checked={isCardActive}
                    onChange={() => changeHandler(true)}
                />
                {isPopular && (
                    <CardLabel
                        className={styles.priceCardLabel}
                        title={t('paymentFlow.paymentIntroductory.mostPopular')}
                    />
                )}
                {isBestValue && !isPopular && (
                    <CardLabel className={styles.priceCardLabel} title={t('paymentFlow.paymentG4.bestValue')} />
                )}
                <Box className={styles.priceCardBody} paddingX={8} paddingTop={16} paddingBottom={16}>
                    <div className={styles.priceCardLeftCol}>
                        <EasyComponentBasedCheckbox isChecked={isCardActive} />
                        <div className={styles.priceCardTitle}>
                            <Text
                                mb={2}
                                uppercase
                                color={isCardActive ? 'text-secondary' : 'text-secondary-subdued'}
                                bold
                                text={getPeriodTitleData()}
                            />
                            {label && (
                                <Box
                                    paddingX={4}
                                    borderRadius={4}
                                    className={styles.priceCardPeriod}
                                    backgroundColor={isCardActive ? 'secondary-default' : 'surface-hovered'}
                                >
                                    <Text
                                        uppercase
                                        bold
                                        color={isCardActive ? 'surface-default' : 'text-secondary-subdued'}
                                        className={styles.priceCardPeriodText}
                                    >
                                        {label}
                                    </Text>
                                </Box>
                            )}
                            {withFullPrice && (
                                <div className={styles.priceCardFullPrices}>
                                    {isDiscount && (
                                        <Text
                                            type="small-text"
                                            color="text-secondary-subdued"
                                            text={`${currencySign} ${
                                                isProcessingFee
                                                    ? processingFeeConvertor(startFullPrice)
                                                    : startFullPrice
                                            }`}
                                            className={cx({ lineThrough: isDiscount })}
                                        />
                                    )}
                                    <Text
                                        type="small-text"
                                        color="text-secondary-subdued"
                                        text={`${currencySign} ${
                                            isProcessingFee ? processingFeeConvertor(fullPrice) : fullPrice
                                        }`}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.priceCardRightCol}>
                        {isDiscount && (
                            <Text
                                type="small-text"
                                color="text-secondary-subdued"
                                text={`${currencySign} ${oldPricePerState}`}
                                className={base.lineThrough}
                            />
                        )}
                        <PriceTag
                            period={perState}
                            currencySign={currencySign}
                            price={pricePerState}
                            isActive={isCardActive}
                        />
                    </div>
                </Box>
            </div>
        </label>
    );
}
