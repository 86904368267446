import { useTranslation } from 'react-i18next';

import { Button, Text } from 'libComponents';
import { TextDes } from 'components/redesigned';
import StoresItems from '../subComponents/StoresItems';
import RatingRow from '../subComponents/RatingRow';
import AppInfo from '../subComponents/AppInfo';

import styles from './SocialProofRatingsFullButton.module.css';

const SocialProofRatingsFullButton = ({ handleRatingButtonClick }: { handleRatingButtonClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <TextDes type="h4" text={t('configurablePayment.socialProof1Button.title')} center />
                <StoresItems gap={28} maxWidth={38} textType="small-text" />
                <div className={styles.ratingsBannerStoresInfo}>
                    <RatingRow gap={16} mb={8} />
                    <AppInfo isBigText />
                    <Text
                        text={t('configurablePayment.socialProofRatingsFull.downloadsTitle')}
                        color="text-secondary-subdued"
                        type="caption-small"
                        center
                        mb={26}
                    />
                </div>
                <Button
                    dataLocator="startPaymentBtn"
                    onClick={handleRatingButtonClick}
                    text="onboardingFlow.paymentI1Golf17.ratingBlock.button"
                    withPulsating
                />
            </div>
        </div>
    );
};

export default SocialProofRatingsFullButton;
