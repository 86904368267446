import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import classNames from 'classnames/bind';

import { setIsPersonalPlan } from 'redux/user/actions';
import { selectCurrency } from 'redux/payment/selectors';

import { sendAnalyticToggleAccessOnAnyDevice } from 'services/Analytics';

import { Text, Image } from 'libComponents';

import styles from './AccessOnAnyDevice.module.css';

import tadaImg from './img/tada.webp';
import devicesImg from './img/Devices.webp';

import Toggle from '../Toggle';

const cx = classNames.bind(styles);

function AccessOnAnyDevice() {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const currency = useSelector(selectCurrency);

    const [isToggle, setToggle] = useState(false);

    const priceWrapper = cx('priceWrapper', { priceWrapperActive: isToggle });

    const toggleHandler = (isActive: boolean) => {
        setToggle(isActive);
        dispatch(setIsPersonalPlan(isActive));
        sendAnalyticToggleAccessOnAnyDevice(isActive);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <Text bold type="large-text" color="text-secondary" text={t('paymentFlow.AccessOnAnyDevice.title')} />
                <Toggle toggled={isToggle} onClick={toggleHandler} />
            </div>
            <Image alt="Devices" src={devicesImg} maxWidth={214} mb={16} />
            <div className={priceWrapper}>
                {isToggle ? (
                    <div className={styles.priceTextWrapper}>
                        <Text>
                            <Trans>paymentFlow.AccessOnAnyDevice.priceText2</Trans>
                        </Text>
                        <Image alt="tada" src={tadaImg} maxWidth={16} className={styles.tadaImg} />
                    </div>
                ) : (
                    <Text color="text-secondary" text={t('paymentFlow.AccessOnAnyDevice.priceText1')} />
                )}
                <Text
                    color="text-secondary-subdued"
                    text={t('paymentFlow.AccessOnAnyDevice.price', { currencySign: currency.sign })}
                    className={cx({ lineTrough: isToggle })}
                />
            </div>
        </div>
    );
}

export default AccessOnAnyDevice;
