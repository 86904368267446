import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { InitConfig } from '@solidgate/client-sdk-loader';

import {
    selectIsApplePayAvailable,
    selectIsDisplayCustomPaymentButton,
    selectIsGooglePayAvailable,
    selectPayment,
} from 'redux/payment/selectors';
import { setIsApplePayAvailable, setIsGooglePayAvailable } from 'redux/payment/actions';

import {
    APPLE_PAY_LITERAL,
    CREDIT_CARD_LITERAL,
    PAYPAL_LITERAL,
    GOOGLE_PAY_LITERAL,
    PAY_PAL_INIT_ID,
} from 'constants/payments';
import { BUILT_IN_PAYMENT_METHOD_FORM_STYLES } from './constants';

import { useSolidHandlers } from 'components/PaymentMethods/hooks/useSolidHandlers';
import { usePaymentData } from 'components/PaymentMethods/hooks/usePaymentData';

import { getApplePayButtonParams, getFormParams, getGooglePayButtonParams } from 'helpers/payment';
import { isAndroid, isIOS } from 'helpers/devices';

import { Button, Image, Text } from 'libComponents';
import PayPalOption from 'components/PayPalOption/PayPalOption';
import SolidPaymentForm from 'components/PaymentMethods/components/SolidPaymentForm';
import Loader from 'components/Loader';

import styles from './BuiltInVerticalPaymentMethods.module.css';

import bankCardImg from '../img/BankCard.webp';

import { BuiltInVerticalPaymentMethodsPropsType } from './types';

const cx = classNames.bind(styles);

const BuiltInVerticalPaymentMethods = ({
    withApplePay = false,
    withGooglePay = false,
    activeOption,
    handleChange,
    payment,
    isPreSelect,
    isHidePayPal,
    onOverlayClick: onLegalCheckboxOverlayClick,
    isLegalCheckboxChecked,
    isBuiltInPaymentMethodHorizontal = false,
}: BuiltInVerticalPaymentMethodsPropsType) => {
    const { isLoading } = useSelector(selectPayment);

    const isApplePayAvailable = useSelector(selectIsApplePayAvailable);
    const isGooglePayAvailable = useSelector(selectIsGooglePayAvailable);

    const isCardFirstOnIOS = isIOS && !isGooglePayAvailable;
    const isCardFirstOnAndroid = isAndroid && isGooglePayAvailable;
    const isAnyOtherDevice = !isAndroid && !isIOS;

    const wrapperClasses = cx('optionsWrapper', {
        'optionsWrapper--changedOrder':
            isPreSelect || (isHidePayPal && (isCardFirstOnIOS || isCardFirstOnAndroid || isAnyOtherDevice)),
        'optionsWrapper--loading': isLoading,
    });

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const appleContainerRef = useRef(null);
    const googleContainerRef = useRef(null);

    const [hasPaymentError, setHasPaymentError] = useState<boolean>(false);
    const [hasPaymentSuccess, setHasPaymentSuccess] = useState<boolean>(false);

    const isDisplayPaymentButton = useSelector(selectIsDisplayCustomPaymentButton);

    const { paymentData, currentProduct, merchantData } = usePaymentData();

    const {
        handleOnError,
        handleOnFail,
        handleOnSuccess,
        handleOnSubmit,
        handleCard,
        handleOnReadyPaymentInstance,
        handleOnMounted,
    } = useSolidHandlers({
        currentProduct,
        paymentData,
        payment,
        activePayment: activeOption,
        setHasPaymentError,
        setHasPaymentSuccess,
    });

    useEffect(() => {
        return () => {
            dispatch(setIsApplePayAvailable(false));
            dispatch(setIsGooglePayAvailable(false));
        };
    }, []);

    useEffect(() => {
        const payPalButton = document.getElementById(PAY_PAL_INIT_ID);

        payPalButton?.addEventListener('button-click', () => handleChange(PAYPAL_LITERAL), false);

        return () => {
            payPalButton?.removeEventListener('button-click', () => handleChange(PAYPAL_LITERAL), false);
        };
    }, []);

    const formParams = getFormParams(isDisplayPaymentButton, false);
    const applePayButtonParams = getApplePayButtonParams(withApplePay);
    const googlePayButtonParams = getGooglePayButtonParams(withGooglePay);

    const inactiveApplePayWrapper = cx('applePayBtn', {
        displayNone: hasPaymentError || hasPaymentSuccess || !isApplePayAvailable,
    });

    const inactiveGooglePayWrapper = cx('googlePayBtn', {
        displayNone: hasPaymentError || hasPaymentSuccess || !isGooglePayAvailable,
    });

    const payPalOneClickWrapper = cx('payPalWrapper', {
        payPalOrder: isApplePayAvailable && isPreSelect,
    });

    const isHideOneClick = !isApplePayAvailable && !isGooglePayAvailable && isHidePayPal;

    return (
        <div className={wrapperClasses}>
            {isLoading && (
                <div className={styles.optionsWrapper__overlay}>
                    <Loader isLoading additionalClass={styles.optionsWrapper__overlayLoader} />
                </div>
            )}

            <div
                className={cx('oneClickOption', { paymentInactive: isHideOneClick })}
                data-locator="oneClickOption"
                onClick={onLegalCheckboxOverlayClick}
            >
                <div
                    className={cx('oneClickOption__list', {
                        legalCheckboxOverlay: onLegalCheckboxOverlayClick && !isLegalCheckboxChecked,
                        'oneClickOption__list--horizontal': isBuiltInPaymentMethodHorizontal,
                    })}
                >
                    {/* Due to PayPal specificity, it is impossible to trigger handleChange in place */}
                    {/* handleChange(PAYPAL_LITERAL) method is triggered on PayPal button click listener */}
                    <div className={payPalOneClickWrapper}>
                        {!isHidePayPal && (
                            <PayPalOption
                                isPayPal
                                withNoSaferStringAppear
                                titleTop={false}
                                buttonClass={styles.payPalButton}
                            />
                        )}
                    </div>
                    <div
                        onClick={() => handleChange(APPLE_PAY_LITERAL)}
                        ref={appleContainerRef}
                        className={inactiveApplePayWrapper}
                    />
                    <div
                        ref={googleContainerRef}
                        className={inactiveGooglePayWrapper}
                        onClick={() => handleChange(GOOGLE_PAY_LITERAL)}
                    />
                </div>
            </div>

            <div className={styles.optionsWrapper__divider}>
                <div className={styles.optionsWrapper__dividerLine} />
                <Text
                    text={t('paymentI3Golf18.verticalPaymentMethodsBlock.divider')}
                    color="text-secondary-subdued"
                    center
                    uppercase
                    medium
                    type="small-text"
                    className={styles.optionsWrapper__dividerText}
                />
                <div className={styles.optionsWrapper__dividerLine} />
            </div>

            <label className={cx('creditCardOption')} onClick={() => handleChange(CREDIT_CARD_LITERAL)}>
                <input type="checkbox" name="option" className={styles.creditCardOption__input} />
                <div className={styles.creditCardOption__container} data-locator="creditCardOption">
                    <div className={styles.creditCardOption__toggler}>
                        <div className={styles.creditCardOption__togglerTitle}>
                            <Text
                                className={styles.creditCardOption__togglerPaymentName}
                                text={t('paymentFlow.verticalCheckout.paymentName.CreditCard')}
                            />
                            <div className={styles.creditCardOption__togglerImages}>
                                <Image src={bankCardImg} alt="imgPayment" maxWidth={144} isForceVisible />
                            </div>
                        </div>
                    </div>
                    <div className={styles.creditCardOption__body} onClick={onLegalCheckboxOverlayClick}>
                        <div
                            className={cx({
                                legalCheckboxOverlay: onLegalCheckboxOverlayClick && !isLegalCheckboxChecked,
                            })}
                        >
                            <SolidPaymentForm
                                applePayButtonParams={applePayButtonParams}
                                applePayContainerRef={appleContainerRef}
                                googlePayButtonParams={googlePayButtonParams}
                                googlePayContainerRef={googleContainerRef}
                                merchantData={merchantData as InitConfig['merchantData']}
                                setHasPaymentError={setHasPaymentError}
                                styles={BUILT_IN_PAYMENT_METHOD_FORM_STYLES}
                                formParams={formParams}
                                onError={handleOnError}
                                onFail={handleOnFail}
                                onCard={handleCard}
                                onSuccess={handleOnSuccess}
                                onSubmit={handleOnSubmit}
                                onReadyPaymentInstance={handleOnReadyPaymentInstance}
                                onMounted={handleOnMounted}
                                key={merchantData?.signature}
                                isOpenModal
                            />
                            {isDisplayPaymentButton && !hasPaymentError && !hasPaymentSuccess && (
                                <div id="customSubmitButton" className={styles.creditCardOption__bankCardBtn}>
                                    <Button text="basics.continue" dataLocator="submitButton" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default BuiltInVerticalPaymentMethods;
