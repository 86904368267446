import { BrainTreeSubscriptionPayload } from 'api/types/request';

import { GA_USER_ID } from 'constants/user';

import { getCookie } from 'helpers/utils';

import { ValidateResponse } from 'types/payments/validate';
import {
    UpsaleData,
    PaymentData,
    IPaymentFailToReteno,
    IPaymentFailToRetenoResponse,
    IValidateData,
    IReplaceSubscription,
    MerchantDataParamsType,
    MerchantDataResponseType,
} from 'types/payments/paymentApiInterfaces';
import { SubscribeRequestResponseType } from 'types/payments/brainTreeInit';

import Base from './Base';

class PaymentApi extends Base {
    async getBraintreeToken() {
        return await this.apiClient.private.get<string>('braintree/get-token');
    }

    async brainTreeSubscription<Data = BrainTreeSubscriptionPayload, Response = SubscribeRequestResponseType>(
        payload: Data
    ): Promise<Response> {
        return await this.apiClient.private.post<Data, Response>('braintree/subscription', payload);
    }

    async upSale<Data = UpsaleData, Response = PaymentData>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('payments/recurring', payload);
    }

    async paymentFailToReteno<Data = IPaymentFailToReteno, Response = IPaymentFailToRetenoResponse>(
        payload: Data
    ): Promise<Partial<Response>> {
        return await this.apiClient.private.post<Data, Response>('reteno/event', payload);
    }

    async postInit<Data = IValidateData, Response = ValidateResponse>(payload: Data): Promise<Partial<Response>> {
        const gaId = getCookie(GA_USER_ID);

        return await this.apiClient.private.post<Data, Response>('payments/post-init', payload, {
            headers: {
                ...(gaId && { 'google-analytics-id': gaId }),
            },
        });
    }

    async replaceSubscription<Data = IReplaceSubscription, Response = PaymentData>(
        payload: Data
    ): Promise<Partial<Response>> {
        return await this.apiClient.private.post<Data, Response>('replace-subscription', payload);
    }

    async getMerchantData<Data = MerchantDataParamsType, Response = MerchantDataResponseType>(
        payload: Data
    ): Promise<Response> {
        return await this.apiClient.private.post<Data, Response>('payments/merchant-data', payload);
    }
}

export default PaymentApi;
