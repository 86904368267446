import { useState } from 'react';

import styles from './Toggle.module.css';

function Toggle({ toggled = false, onClick }: { toggled?: boolean; onClick?: (arg: boolean) => void }) {
    const [isToggled, setIsToggled] = useState(toggled);

    const onClickHandler = () => {
        setIsToggled(!isToggled);
        onClick && onClick(!isToggled);
    };

    return (
        <label className={styles.wrapperToggle}>
            <input className={styles.checkbox} type="checkbox" defaultChecked={isToggled} onClick={onClickHandler} />
            <span className={styles.checkboxView} />
        </label>
    );
}

export default Toggle;
