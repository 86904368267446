import { DATE_FORMATS } from 'constants/measureUnits';

import Convert from 'helpers/Convert';

import dayjs, { Dayjs } from 'dayjs';

const getLossKgPerWeek = (bmi: number) => {
    switch (true) {
        case bmi <= 24.9:
            return 1;

        case bmi >= 25 && bmi <= 29.9:
            return 1.5;

        case bmi >= 30 && bmi <= 34.9:
            return 1.7;

        default:
            return 2;
    }
};

const getTargetDate = (weightDiff: number, weightPerWeek: number) => {
    const weeksCount = weightDiff === 0 ? 0 : Math.abs(weightDiff / weightPerWeek);

    return dayjs().add(Math.round(weeksCount), 'week');
};

const getMonthsArray = (monthsCount: number, targetDate: Dayjs, currentWeight: number, targetWeight: number) => {
    const currentMonth = dayjs().format(DATE_FORMATS.MMM);

    const monthList = [currentMonth];
    const isWeightStable = currentWeight === targetWeight;
    const isWeightIncreased = currentWeight < targetWeight;

    // stable weight requires 1 element in the array (start month)
    if (isWeightStable) return monthList;

    // weight gain requires 2 elements in the array (start month and end month)
    if (isWeightIncreased) {
        monthList.push(dayjs().add(monthsCount, 'month').format(DATE_FORMATS.MMM));

        return monthList;
    }

    // weight loss requires 5 elements in the array (range with 5 months: start month, ..., end month)
    if (monthsCount < 5) {
        for (let i = 1; i <= 4; i++) {
            monthList.push(dayjs().add(i, 'month').format(DATE_FORMATS.MMM));
        }
    } else {
        // This condition is for the case when user want to gain weight in a long distance
        monthList.push(
            dayjs().add(1, 'month').format(DATE_FORMATS.MMM),
            dayjs(targetDate).add(-1, 'month').format(DATE_FORMATS.MMM),
            targetDate.format(DATE_FORMATS.MMM),
            dayjs(targetDate).add(1, 'month').format(DATE_FORMATS.MMM)
        );
    }

    return monthList;
};

// TODO: correct type for convert after rewrite Convert() on ts
const getWeightList = (
    currentWeight: number,
    targetWeight: number,
    unit: string,
    convert: Convert,
    monthsCount: number,
    weeksCount: number,
    weightPerWeek: number
) => {
    const weightList = [currentWeight];
    const monthlyWeightLoss = Math.round(weightPerWeek * 4);
    const periodCount = monthsCount || (weeksCount && 1);
    const weightDiff = currentWeight - targetWeight;

    if (weightDiff < 0) {
        weightList.push(targetWeight);
    } else {
        switch (true) {
            case periodCount === 1:

            case periodCount === 2:
                weightList.push(targetWeight);
                break;

            case periodCount === 3:
                weightList.push(currentWeight - monthlyWeightLoss, targetWeight);
                break;

            case periodCount > 3:
                weightList.push(currentWeight - monthlyWeightLoss, targetWeight + monthlyWeightLoss, targetWeight);
                break;

            default:
                break;
        }
    }

    if (unit === 'lbs') {
        return weightList.map((weightInKg) => convert.fromKgToLbs(weightInKg));
    }

    return weightList;
};

export const getOccasionResultConfig = (
    currentWeight: number,
    targetWeight: number,
    bmi: number,
    unit: string,
    convert: Convert
) => {
    const weightDiff = currentWeight - targetWeight;
    const weightPerWeek = getLossKgPerWeek(bmi);
    const targetDate = getTargetDate(weightDiff, weightPerWeek);

    const weeksCount = dayjs(targetDate).diff(dayjs(), 'week');
    const monthsCount = Math.ceil(dayjs(targetDate).diff(dayjs(), 'month', true));

    const weightList = getWeightList(
        currentWeight,
        targetWeight,
        unit,
        convert,
        monthsCount,
        weeksCount,
        weightPerWeek
    );
    const monthsArray = getMonthsArray(monthsCount, targetDate, currentWeight, targetWeight);

    return { targetDate, weightList, monthsArray };
};
